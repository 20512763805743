import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const DELETE_DOCUMENT_VERIFICATION = `${namespace}/DELETE_DOCUMENT_VERIFICATION`;

const DELETE_DOCUMENT_VERIFICATION_MUTATION = gql`
  mutation deleteDocumentVerification(
    $payload: DeleteDocumentVerificationInput!
  ) {
    deleteDocumentVerification(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const deleteDocumentVerification = (payload, notUpdateStore) => ({
  type: DELETE_DOCUMENT_VERIFICATION,
  payload: {
    key: 'deleteDocumentVerification',
    notUpdateStore,
    graphql: {
      mutation: DELETE_DOCUMENT_VERIFICATION_MUTATION,
      variables: { payload },
    },
  },
});
