export const EMAIL_CLIENT_HEIGHT = 726;
export const LINE_THICKNESS = 1;
export const EMAIL_CLIENT_HEADER_HEIGHT = 64;
export const EMAIL_CLIENT_TEXT_EDITOR_HEIGHT = 145;
export const EMAIL_CLIENT_MESSAGES_LIST_HEIGHT =
  EMAIL_CLIENT_HEIGHT -
  EMAIL_CLIENT_HEADER_HEIGHT -
  EMAIL_CLIENT_TEXT_EDITOR_HEIGHT -
  4 * LINE_THICKNESS;
export const EMAIL_CLIENT_CONVERSATIONS_LIST_HEIGHT =
  EMAIL_CLIENT_HEIGHT - EMAIL_CLIENT_HEADER_HEIGHT - 3 * LINE_THICKNESS;
