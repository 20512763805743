import qs from 'query-string';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import Preloader from '@common/components/Preloader';
import { APPLICATION_PATH } from '@modules/router/constants';

import { OrganizationFeatureProvider } from '@modules/add-on/containers';
import { useAppSelector } from '@store/hooks';
import { useEffect } from 'react';
import { getIsAuthenticated } from '../../selectors';
import { useInitialOrganization } from './hooks';
import { useCurrentOrganizationMember } from './hooks/useCurrentOrganizationMember';

const PrivateRouteGuardLayout = ({
  redirect,
}: {
  redirect?: string;
}) => {
  const { organizationSlugifyName } = useParams();
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const { pathname, search } = useLocation();
  const { fetchInitialOrganization, isOrganizationLoading, organization } =
    useInitialOrganization();
  const {
    currentOrganizationMember,
    loadingCurrentOrganizationMember,
    currentOrganizationMemberError,
  } = useCurrentOrganizationMember();

  useEffect(() => {
    if (!isAuthenticated) return;

    fetchInitialOrganization();
  }, [isAuthenticated, fetchInitialOrganization]);

  if (!isAuthenticated) {
    const redirectQsParams = qs.stringify({
      // Saving the destination url in redirect_url
      ...(pathname !== APPLICATION_PATH.root && {
        redirect_url: pathname + search,
      }),
    });
    return <Navigate to={`/login?${redirectQsParams}`} />;
  }

  if (currentOrganizationMemberError) {
    throw new Error(
      'Unable to find a Organization member for the authenticated user',
    );
  }

  if (
    isOrganizationLoading ||
    !currentOrganizationMember ||
    loadingCurrentOrganizationMember
  ) {
    return <Preloader />;
  }

  // check if loaded organization is different from the one in the url
  //? user may be removed from the organization or the organization may be deleted
  const shouldUpdateUrl =
    organizationSlugifyName &&
    organization?.slugifyName !== organizationSlugifyName;

  if ((redirect && organization) || (shouldUpdateUrl && organization)) {
    return <Navigate to={`/${organization.slugifyName}/dashboard`} replace />;
  }

  return (
    <OrganizationFeatureProvider features={organization?.features || []}>
      <Outlet />
    </OrganizationFeatureProvider>
  );
};

export default PrivateRouteGuardLayout;
