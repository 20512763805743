import { AddressAutocompleteInput } from '@common/components/AddressAutocompleteInput';
import {
  CellEditorMultiSelect,
  CellEditorPopup,
  type CustomCellRendererProps,
} from '@common/components/Table';
import {
  ATTRIBUTE_TYPES,
  type Address,
  DEFAULT_EMPTY_SELECT_VALUE,
} from '../constants';

export const EditorSelector = (params: any) => {
  const { data } = params;

  switch (data.type) {
    case ATTRIBUTE_TYPES.Number:
      return {
        component: 'agNumberCellEditor',
        params: {
          showStepperButtons: true,
        },
      };
    case ATTRIBUTE_TYPES.MultiSelect:
      return {
        // @ts-expect-error - onValueChange is present in docs but not in type CustomCellRendererProps see @link https://www.ag-grid.com/react-data-grid/cell-editors/#custom-components
        component: ({ value, onValueChange }: CustomCellRendererProps) => {
          return (
            <CellEditorMultiSelect
              value={value}
              onValueChange={onValueChange}
              options={data.options}
            />
          );
        },
      };
    case ATTRIBUTE_TYPES.Text:
      return {
        component: 'agTextCellEditor',
      };
    case ATTRIBUTE_TYPES.Address:
      return {
        component: ({
          value,
          // @ts-expect-error - onValueChange is present in docs but not in type CustomCellRendererProps see @link https://www.ag-grid.com/react-data-grid/cell-editors/#custom-components
          onValueChange,
        }: CustomCellRendererProps<any, string | Address>) => {
          return (
            <CellEditorPopup>
              <AddressAutocompleteInput
                inputValue={
                  (typeof value === 'string' ? value : value?.raw) || ''
                }
                onInputChange={(newValue) => {
                  onValueChange({
                    raw: newValue,
                  });
                }}
                onSelect={({ addressState, addressDescription }) => {
                  onValueChange({
                    ...addressState,
                    raw: addressDescription,
                  });
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              />
            </CellEditorPopup>
          );
        },
        popup: true,
        // avoid popup to hide actions buttons (cancel, save) if they are present
        popupPosition: 'under' as const,
      };
    case ATTRIBUTE_TYPES.Dropdown:
      return {
        component: 'agRichSelectCellEditor',
        params: {
          allowTyping: true,
          filterList: true,
          formatValue: (data: any) => data?.value,
          // DEFAULT_EMPTY_SELECT_VALUE allows users to clear the value
          values: data.nonEmptyDropdownOption
            ? data.options
            : [DEFAULT_EMPTY_SELECT_VALUE, ...data.options],
        },
      };
    case ATTRIBUTE_TYPES.Date:
      return {
        component: 'agDateStringCellEditor',
      };
    default:
      return undefined;
  }
};
