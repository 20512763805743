import { RequirementComplianceStatus } from '@trustlayer/common';

export const REQUEST_RECORDS_TABS = {
  Compliance: {
    name: 'Compliance',
    key: 'compliance',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
} as const;

export const STATUS_TYPES = {
  default: 'default',
  waived: RequirementComplianceStatus.Waived,
  overridden: RequirementComplianceStatus.Overridden,
} as const;

export type STATUS_TYPES = (typeof STATUS_TYPES)[keyof typeof STATUS_TYPES];

export enum ACTION_TYPES {
  edit = 'edit',
  remove = 'remove',
  waive = 'waive',
  override = 'override',
  addNote = 'addNote',
  editNote = 'editNote',
}

export const WAIVER_ACTIONS = [
  {
    label: 'Edit waiver',
    code: ACTION_TYPES.edit,
  },
  {
    label: 'Remove waiver',
    code: ACTION_TYPES.remove,
  },
];

export const OVERRIDE_ACTIONS = [
  {
    label: 'Edit override',
    code: ACTION_TYPES.edit,
  },
  {
    label: 'Remove override',
    code: ACTION_TYPES.remove,
  },
];

export const REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES = {
  deleteRequestRecords: 'mutation deleteRequestRecords',
  createRequestRecords: 'mutation createRequestRecords',
  updateRecordAttributes: 'mutation updateRecordAttributes',
} as const;

export type REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES =
  (typeof REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES)[keyof typeof REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES];
