import { Table } from '@common/components/Table/Table';
import type {
  CellEditingStoppedEvent,
  ColDef,
} from '@common/components/Table/types';
import {
  BulkOperationStatus,
  type RecordAttribute,
} from '@graphql/types/graphql';
import { AttributeValueRenderer } from '@modules/attributes/components/AttributeValueRenderer';
import { EditorSelector } from '@modules/attributes/components/EditorSelector';
import { ATTRIBUTES_TYPENAMES } from '@modules/attributes/constants';
import { formatAttributeToUpdate } from '@modules/attributes/utils/formatAttributeToUpdate';
import type { KeyCreatorParams } from 'ag-grid-community';
import { Alert, Tag, message } from 'antd';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { onAttributeValueChange } from './AttributesTable.utils';
import { useRecordAttributes } from './hooks';

type AttributesTableProps = {
  recordId: string;
};

export const AttributesTable = ({ recordId }: AttributesTableProps) => {
  const tableRef = useRef(null);

  const { getAttributes, updateAttributes, refetchAttributes } =
    useRecordAttributes({
      contextRecordId: recordId,
    });

  const handleOnStopEditing = useCallback(
    ({
      data: attribute,
      valueChanged,
    }: CellEditingStoppedEvent<RecordAttribute>) => {
      if (attribute && valueChanged) {
        updateAttributes({
          attributes: [formatAttributeToUpdate(attribute)],
          onCompleted: (data) => {
            const status = data.updateRecordAttributes.operation.status;
            if (status === BulkOperationStatus.Completed) {
              message.success(`Attribute ${attribute.name} has been updated.`);
              refetchAttributes();
            }

            if (status === BulkOperationStatus.Scheduled) {
              // this state never happens since we are updating a single attribute at a time
              message.success(
                `Attribute ${attribute.name} has been scheduled for update.`,
              );
            }

            if (status === BulkOperationStatus.Failed) {
              message.error(
                `The update of the attribute has failed. Please try again or contact support.`,
              );
            }
          },
        });
      }
    },
    [updateAttributes, refetchAttributes],
  );

  const columns: ColDef<RecordAttribute>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        valueGetter: ({ data }: { data: any }) => ({
          isSystemAttribute:
            data.__typename === ATTRIBUTES_TYPENAMES.SystemAttribute,
          name: data.name,
        }),
        cellRenderer: ({
          value,
        }: {
          value: { isSystemAttribute: boolean; name: string };
        }) => {
          return (
            <>
              <span>{value.name}</span>
              {value.isSystemAttribute && <StyledTag>SYSTEM</StyledTag>}
            </>
          );
        },
      },
      {
        headerName: 'Value',
        flex: 1,
        field: 'value',
        editable: true,
        sortable: false,
        cellEditorSelector: EditorSelector,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        valueSetter: onAttributeValueChange,
        cellRenderer: ({ data }: any) => {
          return <AttributeValueRenderer attribute={data} />;
        },
      },
    ],
    [],
  );

  return (
    <StyledWrapper>
      <StyledTable>
        <StyledAlert
          showIcon
          message="Edit attribute values by double-clicking on the attribute value cell."
          type="info"
          // closable Info - until we don't save the user preferences we keep it open
        />
        <Table
          ref={tableRef}
          isSideBarDisabled
          isPaginationDisabled
          columnDefs={columns}
          getRowData={getAttributes}
          onCellEditStopped={handleOnStopEditing}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTag = styled(Tag)`
  border: none;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 9px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

const StyledTable = styled.section`
  flex: 1;
  margin-bottom: 32px;
`;

const StyledAlert = styled(Alert)`
  margin: 8px;
  padding: 9px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.mineShaft};
`;
