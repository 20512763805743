import { getUser } from '@modules/user/selectors';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { getIsAppAuthenticated } from '@modules/app/selectors';
import { setCurrentOrganizationMember } from '@modules/organization-member/actions/setCurrentOrganizationMember';
import { getCurrentOrganizationMember } from '@modules/organization-member/selectors';
import { getActiveOrganizationId } from '@modules/organization/selectors';

const CURRENT_ORGANIZATION_MEMBER = graphql(`
  query CurrentOrganizationMember($input: OrganizationMemberInput!) {
    organizationMember(input: $input) {
      _id
      role
    }
  }
`);

export const useCurrentOrganizationMember = () => {
  const user = useAppSelector(getUser);
  const isAppAuthenticated = useAppSelector(getIsAppAuthenticated);
  const activeOrganizationId = useAppSelector(getActiveOrganizationId);
  const currentOrganizationMember = useAppSelector(
    getCurrentOrganizationMember,
  );
  const dispatch = useAppDispatch();

  const { loading, error } = useQuery(CURRENT_ORGANIZATION_MEMBER, {
    variables: {
      input: {
        userId: user?._id,
      },
    },
    skip: !activeOrganizationId || !isAppAuthenticated || !user?._id,
    onCompleted: (data) => {
      dispatch(
        setCurrentOrganizationMember(data?.organizationMember || undefined),
      );
    },
  });

  return {
    currentOrganizationMemberError: error,
    currentOrganizationMember,
    loadingCurrentOrganizationMember: loading,
  };
};
