import { Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuidByString from 'uuid-by-string';

import { getModulesData } from '@common/utils/compliance-attributes-helpers';
import { useTrustLayerV2FeatureFlag } from '@modules/feature-flags/hooks';
import SelectFillableFormModal from '@modules/fillable-form/components/SelectFillableFormModal';
import { FILLABLE_FORMS_MODULE_ID } from '@modules/fillable-form/constants';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import * as PartyComplianceProfileActions from '@modules/party-compliance-profile/actions';
import { getParty } from '@modules/party/selectors';
import { fetchTemplates } from '@modules/requirement/actions';
import CustomComplianceModal from '@modules/requirement/components/CustomComplianceModal';
import { getTemplates } from '@modules/requirement/selectors';
import SelectSurveyModal from '@modules/surveys/components/SelectSurveyModal';
import { SURVEY_MODULE_ID } from '@modules/surveys/constants';

import Spinner from '@common/components/Spinner';
import * as ComplianceProfileActions from '../../actions';
import ComplianceProfileEditor from '../../components/ComplianceProfileEditor';
import ComplianceProfileViewDrawer from '../../components/ComplianceProfileViewDrawer';
import SelectDocumentChecklistModal from '../../components/SelectDocumentChecklistModal';
import { AttributeType, DefaultRule, Operator } from '../../constants';
import {
  getCurrentComplianceProfile,
  getCurrentDocumentChecklists,
  getCurrentRequirements,
} from '../../selectors';
import ComplianceDrawerTitleContainer from '../ComplianceDrawerTitleContainer';
import { useLazyComplianceProfile } from './hooks';

const ComplianceProfileContainer = ({
  isDrawer = true,
  isCustomField = false,
  isCustomDefault = false,
  isShow = false,
  setIsShow = () => {},
  onChange,
}) => {
  const { partyId } = useParams();
  const [activeKeyTab, setActiveKeyTab] = useState('');
  const [isCustom, setIsCustom] = useState(isCustomDefault);
  const [complianceProfileName, setComplianceProfileName] = useState('');
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState();
  const [isInit, setIsInit] = useState(false);
  // Modals
  const [isVisibleAddModuleModal, setIsVisibleAddModuleModal] = useState(false);
  const [editModuleId, setEditModuleId] = useState('');
  const [editModuleValue, setEditModuleValue] = useState('');
  const [isVisibleAddSubjectModal, setIsVisibleAddSubjectModal] =
    useState(false);
  const [editSubjectId, setEditSubjectId] = useState('');
  const [editSubjectValue, setEditSubjectValue] = useState('');
  const [selectedSubjectData, setSelectedSubjectData] = useState({});
  const [isVisibleAddChecklistModal, setIsVisibleAddChecklistModal] =
    useState(false);
  const [isVisibleAddFillableFormModal, setIsVisibleAddFillableFormModal] =
    useState(false);
  const [isVisibleSelectSurveyModal, setIsVisibleSelectSurveyModal] =
    useState(false);
  const isParty = Boolean(partyId);
  const templates = useSelector(getTemplates);
  const partyData = useSelector((state) => getParty(state, partyId));
  const partyComplianceProfile = R.propOr(
    null,
    'partyComplianceProfile',
    partyData,
  );
  const currentComplianceProfile = useSelector(getCurrentComplianceProfile);
  const currentRequirements = useSelector(getCurrentRequirements);
  const currentDocumentChecklists = useSelector(getCurrentDocumentChecklists);
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const { complianceModules } = getModulesData(currentRequirements, templates);
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();

  const dispatch = useDispatch();

  const { fetchComplianceProfile, loading: loadingComplianceProfile } =
    useLazyComplianceProfile();

  useEffect(() => {
    if (isEmpty(templates)) {
      dispatch(fetchTemplates);
    }
  }, [dispatch, templates]);

  useEffect(() => {
    setComplianceProfileName(R.propOr('', 'name', currentComplianceProfile));
    setSelectedComplianceProfile({
      _id: currentComplianceProfile?._id,
      name: currentComplianceProfile?.name,
      baseComplianceProfile: currentComplianceProfile?.baseComplianceProfile,
    });
  }, [currentComplianceProfile]);

  useEffect(() => {
    if (!isParty) {
      if (isShow && !isInit) {
        setComplianceProfileName(
          R.propOr('', 'name', currentComplianceProfile),
        );
        if (complianceModules.length > 0) {
          setActiveKeyTab(R.head(complianceModules).moduleId);
        }
        setIsInit(true);
      }
    }
  }, [currentComplianceProfile, isInit, isParty, isShow, complianceModules]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (onChange) {
      onChange({
        ...currentComplianceProfile,
        rules: currentRequirements,
        documentChecklists: currentDocumentChecklists,
      });
    }
  }, [
    currentComplianceProfile,
    currentDocumentChecklists,
    currentRequirements,
  ]);

  const isEdited = useMemo(() => {
    const rulesHaveChanged = !R.equals(
      currentRequirements,
      R.propOr([], 'rules', currentComplianceProfile),
    );

    const checklistsHaveChanged = !R.equals(
      currentDocumentChecklists,
      R.propOr([], 'documentChecklists', currentComplianceProfile),
    );

    return rulesHaveChanged || checklistsHaveChanged;
  }, [
    currentComplianceProfile,
    currentDocumentChecklists,
    currentRequirements,
  ]);

  /**
   * Define custom compliance profile.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (isParty && isEdited && !isCustom && Boolean(partyComplianceProfile)) {
      setIsCustom(true);
      setSelectedComplianceProfile((selectedProfile) => ({
        ...selectedProfile,
        baseComplianceProfile: null,
      }));
      dispatch(
        ComplianceProfileActions.setCurrentComplianceProfile(
          R.compose(
            R.assoc(
              'baseComplianceProfile',
              R.propOr(
                R.prop('_id', currentComplianceProfile),
                'baseComplianceProfile',
                currentComplianceProfile,
              ),
            ),
            R.assoc('_id', null),
          )(currentComplianceProfile),
        ),
      );
    }
  }, [
    isParty,
    currentComplianceProfile,
    currentRequirements,
    currentDocumentChecklists,
  ]);

  /**
   * Reset state.
   */
  useEffect(() => {
    if (!isShow) {
      setIsCustom(false);
      dispatch(ComplianceProfileActions.setCurrentComplianceProfile({}));
      dispatch(ComplianceProfileActions.setCurrentRequirements([]));
      dispatch(ComplianceProfileActions.setCurrentDocumentChecklists([]));
    }
  }, [dispatch, isShow]);

  /**
   * Open modals if edit values have changed.
   */
  useEffect(() => {
    if (editSubjectValue) {
      setIsVisibleAddSubjectModal(true);
    }
    if (editModuleValue) {
      setIsVisibleAddModuleModal(true);
    }
  }, [editModuleValue, editSubjectValue]);

  const setRequirements = (requirements) =>
    dispatch(ComplianceProfileActions.setCurrentRequirements(requirements));

  const setComplianceProfile = (profile) =>
    dispatch(ComplianceProfileActions.setCurrentComplianceProfile(profile));

  const onSaveComplianceProfile = () => {
    setComplianceProfile(
      R.assoc('name', complianceProfileName, currentComplianceProfile),
    );

    if (!isParty) {
      const projectsCount = R.propOr(
        0,
        'projectsCount',
        currentComplianceProfile,
      );
      const partiesCount = R.propOr(
        0,
        'partiesCount',
        currentComplianceProfile,
      );

      if (partiesCount || projectsCount) {
        const content =
          !isTlV2FeatureFlagEnabled && projectsCount ? (
            <div>
              <p>
                Updating this compliance profile will affect parties across{' '}
                <b>{pluralize('project', projectsCount, true)}</b>. This process
                can take several minutes.
              </p>
              <p>Are you sure you want to continue?</p>
            </div>
          ) : (
            <div>
              Your changes will be applied to{' '}
              <b>
                {isTlV2FeatureFlagEnabled
                  ? 'request records'
                  : pluralize('party', partiesCount, true)}
              </b>{' '}
              currently associated with this compliance profile. The compliance
              status for each{' '}
              {isTlV2FeatureFlagEnabled ? 'request record' : 'party'} will be
              updated accordingly.
            </div>
          );

        const modalReference = Modal.confirm({
          title: `Heads up`,
          content,
          okText: 'Yes, save changes',
          cancelText: 'Cancel',
          onOk: () =>
            dispatch(
              ComplianceProfileActions.createOrUpdateComplianceProfile({
                v2: isTlV2FeatureFlagEnabled,
              }),
            ),
          onCancel: () => {
            modalReference.destroy();
          },
          width: 500,
          zIndex: 1006,
        });
      } else {
        dispatch(
          ComplianceProfileActions.createOrUpdateComplianceProfile({
            v2: isTlV2FeatureFlagEnabled,
          }),
        );
      }
    } else {
      dispatch(
        PartyComplianceProfileActions.setPartyComplianceProfile({
          partyId,
          isNotify: true,
        }),
      );
    }
  };

  const showConfirmClose = () => {
    if (currentComplianceProfile.name !== complianceProfileName || isEdited) {
      Modal.confirm({
        title: 'There are unsaved changes. Do you want to save your changes?',
        okText:
          isParty || currentComplianceProfile._id
            ? 'Save changes'
            : 'Create compliance profile',
        cancelText: 'Close without saving',
        onOk: () => onSaveComplianceProfile(),
        width: 480,
        onCancel: () => {
          setIsShow(false);
        },
        zIndex: 1005,
      });
    } else {
      setIsShow(false);
    }
  };

  const handleOnSendModule = (
    moduleLabel,
    editModuleValue,
    setIsVisibleAddModuleModal,
    setEditModuleValue,
  ) => {
    moduleLabel = moduleLabel?.trim();

    if (editModuleValue) {
      const requirementsToUpdate = currentRequirements.map((requirement) => {
        if (requirement.moduleId === editModuleId) {
          return { ...requirement, moduleLabel };
        }
        return requirement;
      });
      setRequirements(requirementsToUpdate);
      setIsVisibleAddModuleModal(false);
      setEditModuleValue('');
      setEditModuleId('');
      return;
    }

    setRequirements(
      R.append(
        {
          ...DefaultRule,
          moduleId: uuidByString(moduleLabel?.toLowerCase()),
          moduleLabel,
        },
        currentRequirements,
      ),
    );
    setIsVisibleAddModuleModal(false);
  };

  const handleOnSendSubject = (
    subjectLabel,
    editSubjectValue,
    setIsVisibleAddSubjectModal,
    setEditSubjectValue,
    moduleId,
    moduleLabel,
  ) => {
    subjectLabel = subjectLabel?.trim();

    if (editSubjectValue) {
      const requirementsToUpdate = currentRequirements.map((requirement) => {
        if (requirement.subjectId === editSubjectId) {
          return { ...requirement, subjectLabel };
        }
        return requirement;
      });
      setRequirements(requirementsToUpdate);
      setIsVisibleAddSubjectModal(false);
      setEditSubjectValue('');
      setEditSubjectId('');
      return;
    }

    const definitionWithoutSubjectIndex = R.findIndex(
      (x) => x.moduleId === moduleId && !Boolean(x.subjectId),
      currentRequirements,
    );

    const requirement = {
      ...DefaultRule,
      moduleId,
      moduleLabel,
      subjectId: uuidByString(moduleId.concat(subjectLabel?.toLowerCase())),
      subjectLabel,
    };

    if (definitionWithoutSubjectIndex > -1) {
      setRequirements(
        R.update(
          definitionWithoutSubjectIndex,
          requirement,
          currentRequirements,
        ),
      );
    } else {
      setRequirements(R.append(requirement, currentRequirements));
    }
    setIsVisibleAddSubjectModal(false);
  };

  const handleOnSelectFillableForm = (form) => {
    const uniqAnnotationAssociations = R.compose(
      R.keys,
      R.indexBy(R.path(['customData', 'association'])),
      R.filter(R.has('customData')),
      R.propOr([], 'annotations'),
    )(form);

    const formRequirements = uniqAnnotationAssociations.map((association) => {
      const associationLabel = association === 'party' ? 'Party' : 'Requester';

      return {
        ...DefaultRule,
        moduleId: FILLABLE_FORMS_MODULE_ID,
        moduleLabel: 'Fill & Sign',
        subjectId: form._id,
        subjectLabel: form.name,
        attributeId: `${FILLABLE_FORMS_MODULE_ID}${form._id}Form${associationLabel}`,
        attributeLabel: 'Form',
        operator: 'must be completed by',
        targetValue: association,
        attributeType: 'fillableForm',
        masterDocumentAttributeId: `${form._id}Form${associationLabel}`,
      };
    });

    setRequirements(R.append(formRequirements, currentRequirements));

    setIsVisibleAddFillableFormModal(false);
  };

  const editor = (
    <ComplianceProfileEditor
      activeKeyTab={activeKeyTab}
      documentTypes={[]}
      organizationNamespace={organizationNamespace}
      isParty={isParty}
      isCustomField={isCustomField}
      showConfirmClose={showConfirmClose}
      currentDocumentChecklists={currentDocumentChecklists}
      complianceProfile={currentComplianceProfile}
      setActiveKeyTab={setActiveKeyTab}
      party={partyData}
      setIsVisibleAddModuleModal={setIsVisibleAddModuleModal}
      setIsVisibleAddChecklistModal={setIsVisibleAddChecklistModal}
      setIsVisibleAddFillableFormModal={setIsVisibleAddFillableFormModal}
      openSelectSurveyModal={() => setIsVisibleSelectSurveyModal(true)}
      setEditSubjectId={setEditSubjectId}
      setEditSubjectValue={setEditSubjectValue}
      setEditModuleId={setEditModuleId}
      setEditModuleValue={setEditModuleValue}
      setIsVisibleAddSubjectModal={setIsVisibleAddSubjectModal}
      setSelectedSubjectData={setSelectedSubjectData}
    />
  );

  const requirementSubjects = currentRequirements.map(
    (requirement) => requirement.subjectId,
  );

  const handleOnSelectSurvey = (surveyId, title) => {
    const surveyRequirement = {
      ...DefaultRule,
      moduleId: SURVEY_MODULE_ID,
      moduleLabel: 'Surveys',
      subjectId: surveyId,
      subjectLabel: title,
      attributeId: `${SURVEY_MODULE_ID}${surveyId}`,
      attributeLabel: 'Survey',
      attributeType: AttributeType.Connected,
      masterDocumentAttributeId: surveyId,
      operator: Operator.PRESENT,
    };
    setRequirements(R.append(surveyRequirement, currentRequirements));
  };

  const handleOnSelectedComplianceProfileChange = async (
    selectedComplianceProfile,
  ) => {
    setSelectedComplianceProfile(selectedComplianceProfile);
    const { data } = await fetchComplianceProfile(
      selectedComplianceProfile._id,
    );

    dispatch(
      ComplianceProfileActions.setCurrentComplianceProfile(
        data.getComplianceProfile,
      ),
    );
    dispatch(
      ComplianceProfileActions.setCurrentRequirements(
        data.getComplianceProfile?.rules ?? [],
      ),
    );
    dispatch(
      ComplianceProfileActions.setCurrentDocumentChecklists(
        data.getComplianceProfile?.documentChecklists ?? [],
      ),
    );

    setIsCustom(false);
  };

  return (
    <>
      {isDrawer ? (
        <ComplianceProfileViewDrawer
          isShow={isShow}
          showConfirmClose={showConfirmClose}
          title={
            <ComplianceDrawerTitleContainer
              party={partyData}
              organizationNamespace={organizationNamespace}
              selectedComplianceProfile={selectedComplianceProfile}
              isCurrentComplianceProfileCustom={Boolean(
                currentComplianceProfile.baseComplianceProfile,
              )}
              complianceProfileName={complianceProfileName}
              complianceProfileId={currentComplianceProfile?._id}
              isParty={isParty}
              onSaveComplianceProfile={onSaveComplianceProfile}
              setComplianceProfileName={setComplianceProfileName}
              showConfirmClose={showConfirmClose}
              onSelectedComplianceProfileChange={
                handleOnSelectedComplianceProfileChange
              }
              loadingComplianceProfile={loadingComplianceProfile}
            />
          }
        >
          {loadingComplianceProfile ? <Spinner /> : editor}
        </ComplianceProfileViewDrawer>
      ) : (
        editor
      )}

      <SelectDocumentChecklistModal
        visible={isVisibleAddChecklistModal}
        setIsVisible={setIsVisibleAddChecklistModal}
      />
      <CustomComplianceModal
        open={isVisibleAddModuleModal}
        okText="Add module"
        title="Add new module"
        initialValue={editModuleValue}
        onSend={(moduleLabel) =>
          handleOnSendModule(
            moduleLabel,
            editModuleValue,
            setIsVisibleAddModuleModal,
            setEditModuleValue,
          )
        }
        onCancel={() => setIsVisibleAddModuleModal(false)}
        cancelText="Cancel"
        description="Custom module name:"
        zIndex={1100}
      />
      <CustomComplianceModal
        open={isVisibleAddSubjectModal}
        okText={editSubjectValue ? 'Save' : 'Add requirement group'}
        title={
          editSubjectValue
            ? 'Edit requirement group'
            : 'Add new requirement group'
        }
        initialValue={editSubjectValue}
        onSend={(subjectLabel) => {
          handleOnSendSubject(
            subjectLabel,
            editSubjectValue,
            setIsVisibleAddSubjectModal,
            setEditSubjectValue,
            selectedSubjectData.moduleId,
            selectedSubjectData.moduleLabel,
          );
          setSelectedSubjectData({});
        }}
        onCancel={() => {
          setIsVisibleAddSubjectModal(false);
          setSelectedSubjectData({});
        }}
        cancelText="Cancel"
        description="Requirement group name:"
        zIndex={1100}
      />
      <SelectFillableFormModal
        onCancel={() => setIsVisibleAddFillableFormModal(false)}
        visible={isVisibleAddFillableFormModal}
        onSelect={handleOnSelectFillableForm}
        disabledFillableFormIds={requirementSubjects}
      />
      <SelectSurveyModal
        onSelectSurvey={handleOnSelectSurvey}
        disabledSurveyIds={requirementSubjects}
        visible={isVisibleSelectSurveyModal}
        onClose={() => setIsVisibleSelectSurveyModal(false)}
      />
    </>
  );
};

export default ComplianceProfileContainer;
