import styled from 'styled-components';

import LogoSign from '@common/components/Logo/Sign';
import Spinner from '@common/components/Spinner';

type PreloaderProps = {
  className?: string;
};

const StyledWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  z-index: 99999999;
`;

const Preloader = (props: PreloaderProps) => (
  <StyledWrapper data-cy="preloader">
    <LogoSign width={50} height={50} />
    <div style={{ marginTop: 20 }}>
      <Spinner />
    </div>
  </StyledWrapper>
);

export default Preloader;
