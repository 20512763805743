import { namespace } from '../constants';

export const CREATE_OR_UPDATE_COMPLIANCE_PROFILE = `${namespace}/CREATE_OR_UPDATE_COMPLIANCE_PROFILE`;

export const createOrUpdateComplianceProfile = (payload: {
  v2: boolean;
}) => ({
  type: CREATE_OR_UPDATE_COMPLIANCE_PROFILE,
  payload,
});
