import cloneDeep from 'lodash/cloneDeep';
import type { LegacyQueryFilters } from '../types';

type Params = {
  activeFilters: LegacyQueryFilters;
  contextOptions: {
    primaryRecordId?: string;
    contextRecordId?: string;
  };
};

export const addContextFilters = ({
  activeFilters,
  contextOptions,
}: Params) => {
  const filters = cloneDeep(activeFilters);

  if (contextOptions.primaryRecordId) {
    filters.party = contextOptions.primaryRecordId;
  }

  if (contextOptions.contextRecordId) {
    if (!filters.associatedProjects?.includes(contextOptions.contextRecordId)) {
      filters.associatedProjects = [
        ...(filters?.associatedProjects || []),
        contextOptions.contextRecordId,
      ];
    }
  }

  return filters;
};
