import { getOperationDefinition } from '@apollo/client/utilities';
import omitDeep from '@wertarbyte/omit-deep';
import type { DocumentNode } from 'graphql';

import cloneDeep from 'lodash/cloneDeep';
import * as R from 'ramda';

import { MERGE_TYPE_VARIABLE } from '@graphql/constants';
import type { getContext } from '@store/helpers';

export const getVariablesWithContext = <TOptions>(
  options: TOptions,
  context: ReturnType<typeof getContext>,
) => {
  return R.mergeDeepRight(options || {}, {
    variables: context,
  }) as TOptions;
};

export const getDataWithoutTypename = <T = any>(data?: T): T | undefined => {
  if (!data) {
    return data;
  }

  return omitDeep(cloneDeep(data), ['__typename']) as T;
};

export const getOperationName = (doc: DocumentNode) => {
  const operation = getOperationDefinition(doc);
  // @ts-ignore - don;'t know why `name` is not typed in Apollo
  const key = operation?.selectionSet?.selections[0]?.name?.value;

  return key || '';
};

export const isPaginatedQuery = (context: {
  variables?: {
    mergeType?: string;
  };
}) => {
  return context.variables?.mergeType === MERGE_TYPE_VARIABLE.PAGINATED;
};

export const isInfiniteScrollQuery = (context: {
  variables?: {
    mergeType?: string;
  };
}) => {
  return context.variables?.mergeType === MERGE_TYPE_VARIABLE.INFINITE;
};
