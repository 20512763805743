import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const UPDATE_COMPLIANCE_PROFILE = `${namespace}/UPDATE_COMPLIANCE_PROFILE`;

const UPDATE_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation updateComplianceProfile($payload: UpdateComplianceProfileInput!) {
    updateComplianceProfile(data: $payload) {
      ...ComplianceProfileFrag
    }
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;

export const updateComplianceProfile = (payload) => ({
  type: UPDATE_COMPLIANCE_PROFILE,
  payload: {
    key: 'updateComplianceProfile',
    graphql: {
      mutation: UPDATE_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const UPDATE_COMPLIANCE_PROFILE_V2_MUTATION = gql`
  mutation updateComplianceProfileV2($payload: UpdateComplianceProfileInput!) {
    updateComplianceProfileV2(data: $payload) {
      ...ComplianceProfileFrag
    }
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;

export const updateComplianceProfileV2 = (payload) => ({
  type: UPDATE_COMPLIANCE_PROFILE,
  payload: {
    key: 'updateComplianceProfile',
    graphql: {
      mutation: UPDATE_COMPLIANCE_PROFILE_V2_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
