import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const UPDATE_DOCUMENT_VERIFICATION = `${namespace}/UPDATE_DOCUMENT_VERIFICATION`;

const UPDATE_DOCUMENT_VERIFICATION_MUTATION = gql`
  mutation updateDocumentVerification(
    $payload: UpdateDocumentVerificationInput!
  ) {
    updateDocumentVerification(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const updateDocumentVerification = (payload, notUpdateStore) => ({
  type: UPDATE_DOCUMENT_VERIFICATION,
  payload: {
    key: 'updateDocumentVerification',
    notUpdateStore,
    graphql: {
      mutation: UPDATE_DOCUMENT_VERIFICATION_MUTATION,
      variables: { payload },
    },
  },
});
