import { Col, Pagination, Row, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import DocumentCard from './DocumentCard';
import DocumentsEmptyState from './DocumentsEmptyState';

const columnConfig = {
  sm: { span: 24 },
  md: { span: 12 },
  xl: { span: 8 },
};

const DocumentsCardView = ({
  loading,
  documents,
  organizationNamespace,
  selectedDocuments,
  paginationOptions,
  setSelectedDocuments,
  setSelectedDocument,
  setIsVisibleAssignOneModal,
  areAllDocumentsSelected,
  setSelectedDocumentsCount,
  setAreAllDocumentsSelected,
}) => (
  <DocumentsCardView.Wrapper>
    <DocumentsCardView.ViewWrapper>
      <Spin spinning={loading}>
        <Row type="flex" gutter={[16, 16]} style={{ alignItems: 'stretch' }}>
          {isEmpty(documents) ? (
            <DocumentsCardView.EmptyWrapper>
              <DocumentsEmptyState />
            </DocumentsCardView.EmptyWrapper>
          ) : (
            documents.map((document) => (
              <Col key={`key_${document._id}`} {...columnConfig}>
                <DocumentCard
                  document={document}
                  setAreAllDocumentsSelected={setAreAllDocumentsSelected}
                  organizationNamespace={organizationNamespace}
                  selectedDocuments={
                    areAllDocumentsSelected
                      ? documents.map((party) => party._id)
                      : selectedDocuments
                  }
                  setSelectedDocumentsCount={setSelectedDocumentsCount}
                  setSelectedDocuments={setSelectedDocuments}
                  setSelectedDocument={setSelectedDocument}
                  setIsVisibleAssignOneModal={setIsVisibleAssignOneModal}
                />
              </Col>
            ))
          )}
        </Row>
      </Spin>
    </DocumentsCardView.ViewWrapper>
    <DocumentsCardView.Pagination {...paginationOptions} />
  </DocumentsCardView.Wrapper>
);

DocumentsCardView.Wrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

DocumentsCardView.ViewWrapper = styled.div`
  width: 100%;
  padding: 22px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

DocumentsCardView.EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

DocumentsCardView.Pagination = styled(Pagination)`
  margin-top: 22px !important;
  display: flex;
  align-items: center;
`;

export default DocumentsCardView;
