import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const EDIT_CARRIER_IN_DOCUMENT = `${namespace}/EDIT_CARRIER_IN_DOCUMENT`;

const EDIT_CARRIER_IN_DOCUMENT_MUTATION = gql`
  mutation editCarrierInDocument($payload: EditCarrierInput!) {
    editCarrierInDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const editCarrierInDocument = (payload, notUpdateStore) => ({
  type: EDIT_CARRIER_IN_DOCUMENT,
  payload: {
    key: 'editCarrierInDocument',
    notUpdateStore,
    graphql: {
      mutation: EDIT_CARRIER_IN_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
