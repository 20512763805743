import { gql } from '@apollo/client';

import { CustomFieldsEntities, namespace } from '../constants';
import { CUSTOM_FIELD_FRAGMENT } from '../fragments';

export const UPDATE_PROJECT_CUSTOM_FIELDS = `${namespace}/UPDATE_PROJECT_CUSTOM_FIELDS`;
export const UPDATE_PARTY_CUSTOM_FIELD = `${namespace}/UPDATE_PARTY_CUSTOM_FIELD`;
export const UPDATE_REQUEST_CUSTOM_FIELD = `${namespace}/UPDATE_REQUEST_CUSTOM_FIELD`;

const UPDATE_CUSTOM_FIELD_MUTATION = gql`
  mutation updateCustomField($payload: UpdateCustomFieldInput!) {
    updateCustomField(data: $payload) {
      ...CustomField
    }
  }
  ${CUSTOM_FIELD_FRAGMENT}
`;

const updateCustomField = (entity) => (payload) => {
  const type =
    entity === CustomFieldsEntities.party
      ? UPDATE_PARTY_CUSTOM_FIELD
      : UPDATE_PROJECT_CUSTOM_FIELDS;

  return {
    type,
    payload: {
      key: 'updateCustomField',
      graphql: {
        mutation: UPDATE_CUSTOM_FIELD_MUTATION,
        variables: {
          payload: {
            ...payload,
            associatedEntities: [entity],
          },
        },
      },
    },
  };
};

export const updatePartyCustomField = updateCustomField(
  CustomFieldsEntities.party,
);

export const updateProjectCustomField = updateCustomField(
  CustomFieldsEntities.project,
);
