import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { normalizeContacts } from '../../../utils/normalizeContacts';

export const CONTACTS_QUERY = graphql(`
  query PrimaryRecordContactsToEdit($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      contacts {
        nodes {
          _id
          contactPersonName
          email
        }
      }
    }
  }
`);

export const useContactsQuery = ({
  primaryRecordId,
  skipQuery,
}: {
  primaryRecordId: string;
  skipQuery: boolean;
}) => {
  const { data, loading } = useQuery(CONTACTS_QUERY, {
    variables: {
      primaryRecordInput: {
        id: primaryRecordId,
      },
    },
    skip: skipQuery,
    fetchPolicy: 'no-cache',
  });
  return {
    contacts: normalizeContacts(data?.primaryRecord.contacts.nodes || []),
    loading,
  };
};
