import { Button, Timeline, type TimelineItemProps } from 'antd';
import { useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { getEventLogItem } from '@modules/event-log/components/EventLogTimeline.utils';

import {
  getMemberIdsByNames,
  getTaggedNames,
  mapActivitiesToLegacyEventFields,
  mapMembersToUserTags,
} from './ActivityLogs.utils';
import { useActivityLogs } from './hooks';
import { useMembers } from './hooks/useMembers';

type ActivityLogsProps = {
  recordId: string;
};

export const ActivityLogs = ({ recordId }: ActivityLogsProps) => {
  const [commentEditorState, setCommentEditorState] = useState(
    getEmptyEditorState(),
  );
  const {
    logs,
    totalLogsCount,
    fetchMoreLogs,
    isLoading,
    addMessage,
    isAddingMessage,
  } = useActivityLogs({
    recordId,
  });
  const { taggableMembers, currentUser } = useMembers();

  const membersAsNameTags = mapMembersToUserTags(taggableMembers);

  const isEditorEmpty = isEditorStateBlank(commentEditorState);
  const themeContext = useContext(ThemeContext);

  const timeline = useMemo(() => {
    const normalizedLog = mapActivitiesToLegacyEventFields({
      activities: logs,
    });

    return normalizedLog.map((log) =>
      getEventLogItem({
        log: log,
        themeContext,
        editableComments: false,
      }),
    );
  }, [logs, themeContext]);

  const isLoadMoreButtonVisible =
    timeline.length > 0 && timeline.length < totalLogsCount;

  const handleAddCommentClick = () => {
    const taggedNames = getTaggedNames(getEditorPlainText(commentEditorState));

    const taggedMemberIds = getMemberIdsByNames({
      names: taggedNames,
      members: membersAsNameTags,
    });

    addMessage({
      message: getEditorHTMLContent(commentEditorState),
      user: {
        _id: currentUser?._id || '',
        name: currentUser?.profile.name,
        email: currentUser?.email || '',
        avatar: currentUser?.profile.avatar || '',
      },
      onCompleted: () => setCommentEditorState(getEmptyEditorState()),
      taggedMemberIds,
    });
  };

  return (
    <StyledActivityLogs>
      <StyledTextEditorWrapper>
        <StyledTextEditor
          className="editor"
          isValid
          minHeight={80}
          placeholder="Add a comment"
          editorState={commentEditorState}
          members={membersAsNameTags}
          onChange={setCommentEditorState}
        />
        <Button
          disabled={isEditorEmpty || isAddingMessage}
          className="editorButton"
          onClick={handleAddCommentClick}
        >
          Add Comment
        </Button>
      </StyledTextEditorWrapper>
      <>
        <StyledTimeline items={timeline as TimelineItemProps[]} />
        {isLoadMoreButtonVisible && (
          <StyledLoadMoreButton
            loading={isLoading}
            type="link"
            onClick={fetchMoreLogs}
          >
            Load more
          </StyledLoadMoreButton>
        )}
      </>
    </StyledActivityLogs>
  );
};

const StyledActivityLogs = styled.section`
  --spacing-md: 16px;
  --spacing-sm: 8px;

  padding: var(--spacing-md);
`;

const StyledTextEditorWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
`;

const StyledTextEditor = styled(RichTextEditor)`
  border-radius: 6px;
  flex: 1;
  width: 100%;
`;

const StyledTimeline = styled(Timeline)`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  padding: 26px 0 0 10px;
  font-size: 12px;
  font-weight: normal;

  p.timestamp {
    margin: 3px 0 0;
    font-size: 13px;
  }

  b {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledLoadMoreButton = styled(Button)`
  && {
    padding: 0;
  }
`;
