import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ADD_NEW_CARRIER_TO_DOCUMENT = `${namespace}/ADD_NEW_CARRIER_TO_DOCUMENT`;

// Add new Carrier
const ADD_NEW_CARRIER_TO_DOCUMENT_MUTATION = gql`
  mutation addNewCarrierToDocument($payload: CarrierInput!) {
    addNewCarrierToDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const addNewCarrierToDocument = (payload, notUpdateStore) => ({
  type: ADD_NEW_CARRIER_TO_DOCUMENT,
  payload: {
    key: 'addNewCarrierToDocument',
    notUpdateStore,
    graphql: {
      mutation: ADD_NEW_CARRIER_TO_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
