import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import {
  ASSIGNING_PARTIES_COMPLIANCE_PROFILE,
  ASSIGN_COMPLIANCE_PROFILE,
  UPDATE_PARTY_COMPLIANCE_PROFILE,
  UPDATE_PARTY_COMPLIANCE_PROFILE_STATE,
} from '@modules/party-compliance-profile/actions';
import {
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
} from '@modules/party-contact/actions';
import { REMOVE_PARTY_FROM_PROJECTS } from '@modules/project/actions';
import {
  CREATE_REQUEST,
  FORWARD_REQUEST,
  SEND_REQUEST_REMINDER,
} from '@modules/request/actions';
import {
  ADD_TAGGINGS_TO_PARTIES,
  DELETE_TAGGINGS_FROM_PARTIES,
  UPDATE_TAGGING,
} from '@modules/tagging/actions';
import { getGraphqlPayload, getGraphqlResponse } from '@store/helpers';

import {
  ARCHIVE_DOCUMENT,
  DELETE_DOCUMENT,
  PROCESS_DOCUMENT,
  PROCESS_DOCUMENT_URL,
} from '../../document/actions';
import {
  UPDATE_REQUIREMENTS_STATE,
  UPDATE_REQUIREMENT_STATUS,
} from '../../requirement/actions';
import {
  CREATE_PARTY,
  DELETE_PARTIES,
  DELETE_PARTY,
  FETCH_FLAGS_BY_PARTY_ID,
  FETCH_PARTIES_WITH_DETAILS,
  FETCH_PARTY_REQUIREMENTS,
  FETCH_PARTY_TO_EDIT,
  FETCH_PARTY_WITH_DETAILS,
  FETCH_PARTY_WITH_DETAILS_BY_REQUEST,
  SELECT_DOCUMENT_FOR_COMPLIANCE_CARD,
  SET_IS_CHANGING_COMPLIANCE_PROFILE,
  TOGGLE_COMPLIANCE_TRACKING,
  UPDATE_ADDITIONAL_NOTE,
  UPDATE_PARTIES_ACTIVE_STATUS,
  UPDATE_PARTIES_STATE,
  UPDATE_PARTY,
  UPDATE_PARTY_ACTIVE_STATUS,
  UPDATE_PARTY_CONTACTS_STATE,
  UPDATE_PARTY_STATE,
  UPDATE_SUBJECT_STATUS,
} from '../actions';

const initialState = {
  data: {},
  isSendingRequests: false,
  isChangingComplianceProfile: false,
  assigningProfile: false,
  totalCount: 0,
  selectedDocuments: {},
};

const PartyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DOCUMENT_FOR_COMPLIANCE_CARD: {
      const { partyId, subjectId, documentId } = action.payload;

      return R.assocPath(
        ['selectedDocuments', partyId, subjectId],
        documentId,
        state,
      );
    }
    case ASSIGNING_PARTIES_COMPLIANCE_PROFILE: {
      return R.assoc(
        'assigningProfile',
        R.pathOr(false, ['payload', 'isAssign'], action),
        state,
      );
    }
    case `${PROCESS_DOCUMENT_URL}_SUCCESS`:
    case `${PROCESS_DOCUMENT}_SUCCESS`:
    case `${DELETE_DOCUMENT}_SUCCESS`:
    case `${ARCHIVE_DOCUMENT}_SUCCESS`: {
      const document = R.compose(R.defaultTo({}), getGraphqlPayload)(action);
      const documentPartyId = R.path(['party', '_id'], document);

      if (
        Boolean(documentPartyId) &&
        Boolean(R.prop(documentPartyId, state.data))
      ) {
        const partyData = R.propOr({}, documentPartyId, state.data);
        const partyWithDocuments = R.compose(
          (docs) => R.assoc('documents', docs, partyData),
          R.values,
          R.assoc(document._id, document),
          R.indexBy(R.prop('_id')),
          R.propOr([], 'documents'),
        )(partyData);

        const partyToUpdate = R.compose(
          R.mergeRight(partyWithDocuments),
          R.propOr({}, 'party'),
        )(document);

        return R.assocPath(['data', documentPartyId], partyToUpdate, state);
      }
      return state;
    }
    case `${ADD_TAGGINGS_TO_PARTIES}_SUCCESS`:
    case `${UPDATE_TAGGING}_SUCCESS`:
    case `${DELETE_TAGGINGS_FROM_PARTIES}_SUCCESS`: {
      const payload = Array.isArray(getGraphqlPayload(action))
        ? getGraphqlPayload(action)
        : [getGraphqlPayload(action)];
      const parties = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
      )(payload);

      return R.mergeDeepRight(state, { data: parties });
    }
    case `${UPDATE_PARTIES_ACTIVE_STATUS}_SUCCESS`: {
      const payload = getGraphqlPayload(action);

      return R.assocPath(
        ['data', R.prop('_id', payload), 'isActive'],
        R.prop('isActive', payload),
        state,
      );
    }

    case `${DELETE_PARTIES}_SUCCESS`: {
      const parties = R.compose(
        R.map((party) => R.prop('_id', party)),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);
      const data = R.omit(parties, state.data);
      const totalCount = state.totalCount - parties.length;

      return R.mergeRight(state, {
        data,
        totalCount,
      });
    }
    case FETCH_PARTIES_WITH_DETAILS: {
      return R.compose(R.assoc('data', []), R.assoc('totalCount', 0))(state);
    }
    case `${FETCH_PARTIES_WITH_DETAILS}_SUCCESS`: {
      const data = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        R.map((party) => {
          const primaryContact = R.find(
            R.propEq('isPrimary', true),
            R.propOr([], 'partyContacts', party),
          );
          if (primaryContact) {
            party.primaryContact = {
              _id: primaryContact._id,
              contactPersonName: R.propOr(
                '',
                'contactPersonName',
                primaryContact,
              ),
              phone: R.propOr('', 'phone', primaryContact),
              email: R.propOr('', 'email', primaryContact),
              fax: R.propOr('', 'fax', primaryContact),
              title: R.propOr('', 'title', primaryContact),
            };
          }

          return party;
        }),
        R.propOr([], 'data'),
        getGraphqlPayload,
      )(action);

      const totalCount = R.compose(
        R.propOr(0, 'totalCount'),
        getGraphqlPayload,
      )(action);

      return R.compose(
        R.assoc('data', data),
        R.assoc('totalCount', totalCount),
      )(state);
    }
    case `${FETCH_PARTY_REQUIREMENTS}_SUCCESS`: {
      const { _id, requirements } = getGraphqlPayload(action);
      return R.assocPath(['data', _id, 'requirements'], requirements, state);
    }
    case `${FETCH_FLAGS_BY_PARTY_ID}_SUCCESS`: {
      const { _id, partyFlags } = getGraphqlPayload(action);
      return R.assocPath(['data', _id, 'partyFlags'], partyFlags, state);
    }
    case UPDATE_PARTIES_STATE: {
      const data = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        R.map((party) => {
          const primaryContact = R.find(
            R.propEq('isPrimary', true),
            R.propOr([], 'partyContacts', party),
          );
          party.primaryContact = {
            _id: primaryContact._id,
            contactPersonName: R.propOr(
              '',
              'contactPersonName',
              primaryContact,
            ),
            phone: R.propOr('', 'phone', primaryContact),
            email: R.propOr('', 'email', primaryContact),
            fax: R.propOr('', 'fax', primaryContact),
            title: R.propOr('', 'title', primaryContact),
          };

          return party;
        }),
        R.pathOr([], ['payload', 'data']),
      )(action);

      const totalCount = R.compose(R.pathOr(0, ['payload', 'totalCount']))(
        action,
      );

      return R.mergeRight(state, {
        data,
        totalCount,
      });
    }
    case UPDATE_PARTY_CONTACTS_STATE: {
      const newPartyContact = R.pathOr(
        [],
        ['payload', 'data', 'conversationUpdates', 'newPartyContact'],
        action,
      );

      if (!R.isNil(newPartyContact) && !isEmpty(newPartyContact)) {
        const partyId = R.pathOr(
          null,
          [
            'payload',
            'data',
            'conversationUpdates',
            'partyConversation',
            'party',
          ],
          action,
        );

        return R.assocPath(
          ['data', partyId, 'partyContacts'],
          R.append(newPartyContact)(
            R.path(['data', partyId, 'partyContacts'], state),
          ),
          state,
        );
      }

      return state;
    }
    case `${CREATE_REQUEST}_SUCCESS`:
    case `${SEND_REQUEST_REMINDER}_SUCCESS`:
    case `${FORWARD_REQUEST}_SUCCESS`: {
      const party = getGraphqlPayload(action);
      const updatedParty = R.mergeAll([
        R.path(['data', party._id], state),
        R.pick(
          ['requests', 'isSendingExpiringRequest', 'lastRequestSentOn'],
          party,
        ),
      ]);

      return R.assocPath(['data', party._id], updatedParty, state);
    }
    case `${UPDATE_SUBJECT_STATUS}_SUCCESS`:
    case `${UPDATE_REQUIREMENT_STATUS}_SUCCESS`: {
      const party = getGraphqlResponse(action);
      return R.assocPath(
        ['data', party._id],
        R.mergeRight(R.path(['data', party._id], state), party),
        state,
      );
    }
    case `${UPDATE_ADDITIONAL_NOTE}_SUCCESS`: {
      const payload = getGraphqlPayload(action);

      return R.assocPath(
        ['data', R.prop('_id', payload), 'additionalNotes'],
        R.prop('additionalNotes', payload),
        state,
      );
    }
    case `${CREATE_CONTACT}_SUCCESS`:
    case `${UPDATE_CONTACT}_SUCCESS`:
    case `${DELETE_CONTACT}_SUCCESS`: {
      const payload = getGraphqlPayload(action);

      return R.assocPath(
        ['data', R.prop('_id', payload), 'partyContacts'],
        R.prop('partyContacts', payload),
        state,
      );
    }
    case `${ASSIGN_COMPLIANCE_PROFILE}_SUCCESS`:
    case `${UPDATE_PARTY_COMPLIANCE_PROFILE}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      const partyComplianceProfile = R.omit(['_id'], payload);
      const partyId = R.prop('_id', payload);

      return R.compose(
        R.assocPath(
          ['data', partyId],
          R.mergeLeft(partyComplianceProfile, R.path(['data', partyId], state)),
        ),
      )(state);
    }
    case `${TOGGLE_COMPLIANCE_TRACKING}_SUCCESS`: {
      const party = getGraphqlPayload(action);

      const updatedParty = R.mergeDeepRight(
        R.path(['data', party._id], state),
        R.assoc('partyComplianceProfile', null, party),
      );

      return R.assocPath(['data', party._id], updatedParty, state);
    }
    case `${REMOVE_PARTY_FROM_PROJECTS}_SUCCESS`:
    case `${FETCH_PARTY_TO_EDIT}_SUCCESS`:
    case `${CREATE_PARTY}_SUCCESS`:
    case `${UPDATE_PARTY}_SUCCESS`:
    case `${FETCH_PARTY_WITH_DETAILS_BY_REQUEST}_SUCCESS`:
    case `${FETCH_PARTY_WITH_DETAILS}_SUCCESS`: {
      if (!getGraphqlPayload(action)) return state;

      const party = R.compose(
        R.defaultTo({}),
        (party) => ({
          primaryContact: R.find(
            R.propEq('isPrimary', true),
            R.propOr([], 'partyContacts', party),
          ),
          ...party,
        }),
        getGraphqlPayload,
      )(action);

      return R.assocPath(['data', party._id], party, state);
    }

    case `${UPDATE_PARTY_ACTIVE_STATUS}_SUCCESS`:
    case `${DELETE_PARTY}_SUCCESS`: {
      const party = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.mergeRight(state, {
        data: R.omit([party._id], state.data),
        totalCount: state.totalCount - 1,
      });
    }
    case UPDATE_REQUIREMENTS_STATE:
    case UPDATE_PARTY_COMPLIANCE_PROFILE_STATE:
    case UPDATE_PARTY_STATE: {
      const party = R.compose(
        (party) => ({
          ...party,
          primaryContact: R.find(
            R.propEq('isPrimary', true),
            R.propOr([], 'partyContacts', party),
          ),
        }),
        R.pathOr({}, ['payload', 'data', 'documentProcessed', 'partyData']),
      )(action);

      return R.assocPath(['data', party._id], party, state);
    }
    case SET_IS_CHANGING_COMPLIANCE_PROFILE: {
      return R.assoc('isChangingComplianceProfile', action.payload, state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default PartyReducer;
