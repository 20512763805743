import * as R from 'ramda';

import { ROLE_SUPPORT } from '@modules/organization-member/models/data';

import { STATE_KEY } from '../constants';

export const getOrganizationMembers = R.compose(
  R.values,
  R.pathOr([], [STATE_KEY, 'data']),
);

export const getOrganizationMember = (state, id) =>
  R.path([STATE_KEY, 'data', id], state);

export const getOrganizationMemberByUserId = (state, userId) =>
  R.compose(
    R.find(R.pathEq(['user', '_id'], userId)),
    R.values,
    R.pathOr([], [STATE_KEY, 'data']),
  )(state);

export const getIsCustomerSupport = (state) =>
  R.compose(
    R.find(R.propEq('role', ROLE_SUPPORT)),
    R.values,
    R.pathOr([], [STATE_KEY, 'data']),
  )(state);

export const getCurrentOrganizationMember = (state) => state[STATE_KEY].current;
