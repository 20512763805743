import { Avatar, Button } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import UserAvatar from '@common/components/UserAvatar';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { getParty } from '@modules/party/selectors';
import { getCurrentProject } from '@modules/project/selectors';
import { getUser } from '@modules/user/selectors';

import { useTaggableMembers } from '@common/components/RichTextEditor/hooks/useTaggableMembers';
import EventLogBox from '../components/EventLogBox';
import { EventType } from '../constants';
import usePartyEventLogs from '../hooks/usePartyEventLogs';
import { getEventLogsTotalCount } from '../selectors';

const PartyEventLogContainer = ({ partyId }) => {
  const [commentEditorState, setCommentEditorState] = useState(
    getEmptyEditorState(),
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [usersToSend, setUsersToSend] = useState([]);

  const {
    partyEventLogs: timeline,
    createEvent,
    isTimelineLoading,
    isTimelineLoadingMore,
    fetchMore,
  } = usePartyEventLogs({ partyId });

  const { taggableMembers } = useTaggableMembers();

  const organization = useSelector(getActiveOrganizationData);
  const partyData = useSelector((state) => getParty(state, partyId));
  const totalEventsCount = useSelector(getEventLogsTotalCount);
  const {
    _id,
    email,
    profile: { name, avatar },
  } = useSelector(getUser);
  const currentProject = useSelector(getCurrentProject);

  const onRecordEvent = async () => {
    if (isEditorStateBlank(commentEditorState)) {
      return;
    }

    createEvent({
      variables: {
        payload: {
          type: EventType.CommentCreated,
          data: {
            avatarLink: avatar,
            userName: name,
            userEmail: email,
            message: getEditorHTMLContent(commentEditorState),
            previousVersions: [],
          },
          contextEntityType: 'party',
          contextEntityId: partyId,
          partyId: partyId,
          partyName: partyData?.name,
          projectId: currentProject?._id,
          projectName: currentProject?.name,
          actorType: 'user', // TODO: this should be added by the server...
          actorId: _id, // TODO: this should be added by the server...
          organization: organization.id, // TODO: this should be added by the server...
          usersToSend,
        },
      },
      onCompleted: () => setCommentEditorState(getEmptyEditorState()),
    });
  };

  const isEmptyComment = isEditorStateBlank(commentEditorState);

  return (
    <EventLogBox
      timeline={timeline}
      isTimelineLoading={isTimelineLoading}
      isLoadingMore={isTimelineLoadingMore}
      loadMore={() => fetchMore()}
      hasToLoadMore={totalEventsCount > timeline?.length}
    >
      <>
        <StyledWrapper>
          <StyledAvatarWrapper>
            {avatar ? (
              <Avatar size={40} src={avatar} alt="Avatar" />
            ) : (
              <UserAvatar name={name} email={email} />
            )}
          </StyledAvatarWrapper>
          <div>
            <StyledTextAreaWrapper
              $isFocused={isFocused || isHover}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <RichTextEditor
                isValid
                removeBorder
                minHeight={50}
                placeholder="Enter your comment..."
                members={taggableMembers}
                editorState={commentEditorState}
                onChange={(newState, membersIds) => {
                  setCommentEditorState(newState);
                  setUsersToSend(membersIds);
                }}
              />
            </StyledTextAreaWrapper>
            <StyledButton
              data-cy="addCommentButton"
              type="primary"
              disabled={isEmptyComment}
              ghost={isEmptyComment}
              onClick={() => onRecordEvent()}
            >
              Comment
            </StyledButton>
          </div>
        </StyledWrapper>
        {timeline?.length > 0 && (
          <>
            <StyledBottomBorder />
            <StyledVerticalLine />
          </>
        )}
      </>
    </EventLogBox>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-left: 61px;
`;

const StyledBottomBorder = styled.div`
  margin-left: 61px;
  height: 3px;
  background-color: #f3f6f8;
`;

const StyledVerticalLine = styled.div`
  margin-left: 77px;
  border-left: 3px solid #f3f6f8;
  height: 28px;
`;

const StyledAvatarWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 6px;
`;

const StyledButton = styled(Button)`
  margin-top: 13px;
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  padding: 1px;
  border: 1px solid ${(props) => (props.$isFocused ? '#40a9ff' : '#d9d9d9')};
  border-radius: 5px;

  textarea {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &&::after,
  &&::before {
    position: absolute;
    right: 100%;
    top: 23px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &&::after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 7px;
    margin-top: -7px;
  }

  &&::before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: ${(props) => (props.$isFocused ? '#40a9ff' : '#d9d9d9')};
    border-width: 8px;
    margin-top: -8px;

    .test:hover {
      border-right-color: #40a9ff;
    }
  }
`;

export default PartyEventLogContainer;
