import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';

import { CREATE_PARTY } from '@modules/party/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_PROJECT,
  DELETE_PROJECTS,
  FETCH_CURRENT_PROJECT_BY_ID,
  FETCH_PARTY_COMPLIANCE_BY_PROJECT,
  FETCH_PARTY_PROJECTS,
  FETCH_PROJECTS,
  FETCH_PROJECTS_BY_REQUEST,
  FETCH_PROJECT_BY_ID,
  REMOVE_PARTIES_FROM_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECTS_ACTIVE_STATUS,
  UPDATE_SUBSCRIBED_PROJECTS_MAP,
} from '../actions';

const initialState = {
  data: {},
  currentProject: {},
  totalCount: 0,
  isLoading: false,
  subscribedProjectsMap: {},
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROJECTS_BY_REQUEST}_SUCCESS`: {
      const data = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', data, state);
    }
    case FETCH_PROJECTS:
    case FETCH_PARTY_PROJECTS: {
      return R.compose(R.assoc('data', []), R.assoc('totalCount', 0))(state);
    }
    case `${FETCH_PROJECTS}_SUCCESS`:
    case `${FETCH_PARTY_PROJECTS}_SUCCESS`: {
      const data = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        R.propOr([], 'data'),
        getGraphqlPayload,
      )(action);

      const totalCount = R.compose(
        R.propOr(0, 'totalCount'),
        getGraphqlPayload,
      )(action);

      return R.compose(
        R.assoc('totalCount', totalCount),
        R.assoc('data', data),
      )(state);
    }
    case `${CREATE_PROJECT}_SUCCESS`: {
      const project = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.compose(
        R.assoc('totalCount', state.totalCount + 1),
        R.assocPath(['data', project._id], project),
      )(state);
    }
    case FETCH_CURRENT_PROJECT_BY_ID: {
      return R.assoc('isLoading', true, state);
    }
    case `${FETCH_CURRENT_PROJECT_BY_ID}_SUCCESS`:
    case `${REMOVE_PARTIES_FROM_PROJECT}_SUCCESS`: {
      const currentProject = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.compose(
        (state) => {
          if (isEmpty(currentProject)) {
            return state;
          }

          return R.assocPath(
            ['data', currentProject._id],
            currentProject,
            state,
          );
        },
        R.mergeLeft({ currentProject, isLoading: false }),
      )(state);
    }
    case `${FETCH_PROJECT_BY_ID}_SUCCESS`:
    case `${UPDATE_PROJECT}_SUCCESS`: {
      const project = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.compose(
        (state) =>
          state.currentProject._id === project._id
            ? R.assoc('currentProject', project, state)
            : state,
        R.assocPath(['data', project._id], project),
      )(state);
    }
    case `${UPDATE_PROJECTS_ACTIVE_STATUS}_SUCCESS`:
    case `${DELETE_PROJECTS}_SUCCESS`: {
      const projects = R.compose(
        R.map((project) => R.prop('_id', project)),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);
      const data = R.omit(projects, state.data);
      const totalCount = state.totalCount - projects.length;

      return R.compose(
        R.assoc('totalCount', totalCount),
        R.assoc('data', data),
      )(state);
    }
    case `${CREATE_PARTY}_SUCCESS`: {
      const currentProject = state.currentProject;

      if (!isEmpty(currentProject)) {
        const party = R.compose(
          (x) => ({
            ...R.pick(['_id', 'name'], x),
            type: R.pick(['_id', 'name'], x.type),
            partyComplianceProfile: x.partyComplianceProfile
              ? R.pick(['_id', 'complianceProfile'], x.partyComplianceProfile)
              : null,
          }),
          getGraphqlPayload,
        )(action);

        return R.assocPath(
          ['currentProject', 'parties'],
          [...currentProject.parties, party],
          state,
        );
      }

      return state;
    }
    case `${FETCH_PARTY_COMPLIANCE_BY_PROJECT}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      const partyProjectData = R.head(payload);
      const { party, ...rest } = partyProjectData;

      return R.assocPath(
        ['projectPartyData', party._id],
        {
          ...rest,
          ...party,
        },
        state,
      );
    }
    case UPDATE_SUBSCRIBED_PROJECTS_MAP: {
      const projectId = action?.payload;

      if (!projectId) {
        return state;
      }

      return {
        ...state,
        subscribedProjectsMap: {
          ...state.subscribedProjectsMap,
          [projectId]: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default ProjectReducer;
