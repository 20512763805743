import { EditOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import styled from 'styled-components';

export const normalizeGooglePlacesResult = (result) => {
  const payload = {
    streetNumber: '',
    route: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',
  };

  result.address_components.forEach((ac) => {
    ac.types.forEach((type) => {
      switch (type) {
        case 'street_number':
          payload['streetNumber'] = ac['long_name'];
          break;
        case 'route':
          payload['route'] = ac['long_name'];
          break;
        case 'locality':
          payload['city'] = ac['long_name'];
          break;
        case 'administrative_area_level_1':
          payload['region'] = ac['long_name'];
          break;
        case 'country':
          payload['country'] = ac['long_name'];
          break;
        case 'postal_code':
          payload['postalCode'] = ac['long_name'];
          break;
        default:
          break;
      }
    });
  });

  const streetAddress = `${payload['streetNumber']} ${payload['route']}`.trim();
  const addressLine1 = Boolean(streetAddress.length)
    ? streetAddress
    : R.propOr('', 'long_name', R.head(result.address_components));

  return {
    ...R.pick(['city', 'region', 'country', 'postalCode'], payload),
    addressLine1,
  };
};

const notEmpty = (item) => item && !isEmpty(item);

export const FormattedAddressText = ({
  rawAddress,
  line1,
  line2,
  city,
  region,
  country,
}) => {
  const address = R.join(', ')([city, region, country].filter(notEmpty));
  const tooltip = [line1, line2].filter(notEmpty).join(', ');

  return !isEmpty(address) || !isEmpty(rawAddress) ? (
    <Tooltip mouseEnterDelay={0.5} title={tooltip}>
      <span>{address || rawAddress}</span>
    </Tooltip>
  ) : null;
};

const FormattedAddressBox = ({
  line1,
  line2,
  city,
  region,
  postalCode,
  country,
  onChangeAddress,
}) => {
  const firstPart = [line1, line2].filter(notEmpty).join(', ');
  const secondPart = [city, region, postalCode].filter(notEmpty).join(', ');
  country = R.defaultTo('', country);

  return !isEmpty(firstPart) || !isEmpty(secondPart) || !isEmpty(country) ? (
    <FormattedAddressBox.Wrapper>
      <EnvironmentOutlined />
      <div>
        {Boolean(firstPart.length) && (
          <p data-cy="addressFirstPart">{firstPart}</p>
        )}
        {(Boolean(secondPart.length) || Boolean(country.length)) && (
          <p data-cy="addressSecondPart">
            {[secondPart, country].filter(notEmpty).join(' – ')}
          </p>
        )}
      </div>
      <EditOutlined
        data-cy="changeAddress"
        className="change-address"
        onClick={onChangeAddress}
      />
    </FormattedAddressBox.Wrapper>
  ) : null;
};

FormattedAddressBox.Wrapper = styled.div`
  position: relative;
  padding: 10px 15px;
  display: flex;
  border-radius: 5px;
  font-size: 12px !important;
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.3s;
  margin-bottom: 5px;

  span:first-child {
    margin-top: 4px;
  }

  span:last-child {
    margin-top: 15px;
  }

  &:empty {
    display: none;
  }

  .change-address {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
  }

  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  }

  p {
    padding-left: 7px;
    line-height: 20px;
    margin: 0;
  }
`;

export default FormattedAddressBox;
