import { Input } from 'antd';
import type { SearchProps } from 'antd/es/input';

const DEFAULT_PLACEHOLDER = 'Find by name';

type SearchInputProps = {
  placeholder?: string;
  allowClear?: boolean;
} & SearchProps;

const SearchInput = ({
  placeholder = DEFAULT_PLACEHOLDER,
  allowClear = true,
  ...props
}: SearchInputProps) => (
  <Input.Search
    {...props}
    allowClear={allowClear}
    placeholder={placeholder}
    data-cy="searchByNameInput"
  />
);

export default SearchInput;
