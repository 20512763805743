import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import SearchInput from '@common/components/SearchInput';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { MessagesIssuesQuickView } from '@modules/party-conversation';

import { InifiniteScrollList } from '@common/components/InfiniteScrollList';
import { LoadingSpinner } from '@common/components/Spinner/LoadingSpinner';
import type { PropType } from '@common/types';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { useConversationUpdatesSubscription } from '@modules/primary-records/hooks/useConversationUpdatesSubscription';
import { ConversationEmptyState } from '../../components/ConversationEmptyState';
import { MessageEditor } from '../../components/MessageEditor/MessageEditor';
import { useReplaceConversationParticipants } from '../../hooks/useReplaceConversationParticipants';
import { useSendMessageMutation } from '../../hooks/useSendMessageMutation';
import { AddParticipantsPopover } from '../AddParticipantsPopover';
import { Messages } from '../Messages';
import { ConversationHeader } from './components/ConversationHeader';
import { ConversationPreview } from './components/ConversationPreview';
import {
  EMAIL_CLIENT_CONVERSATIONS_LIST_HEIGHT,
  EMAIL_CLIENT_HEADER_HEIGHT,
  EMAIL_CLIENT_HEIGHT,
  EMAIL_CLIENT_MESSAGES_LIST_HEIGHT,
  EMAIL_CLIENT_TEXT_EDITOR_HEIGHT,
  LINE_THICKNESS,
} from './constants';
import { useConversations } from './hooks';

type ConversationsProps = {
  primaryRecordId: string;
  selectedConversation?: {
    id: string;
    messageId?: string;
  };
  onChange?: (params: {
    conversationId: string;
    messageId?: string;
  }) => void;
};

export const Conversations = ({
  primaryRecordId,
  selectedConversation,
  onChange,
}: ConversationsProps) => {
  const [isEditParticipantsModalVisible, setIsEditParticipantsModalVisible] =
    useState(false);

  const role = useMemberRole();

  const {
    conversationsList,
    totalContactsCount,
    isInitialLoading,
    filterConversationsByMessageOrSubject,
    loading,
    fetchMoreConversations,
    hasMoreConversationsToFetch,
    refetchConversations,
  } = useConversations({ primaryRecordId });

  useConversationUpdatesSubscription({
    partyId: primaryRecordId,
    onData: () => refetchConversations(),
  });

  const selectedConversationId =
    selectedConversation?.id || conversationsList[0]?._id;

  const {
    replaceConversationParticipants,
    loading: loadingReplaceParticipants,
  } = useReplaceConversationParticipants();

  const { sendConversationMessage, loading: loadingSendMessage } =
    useSendMessageMutation(selectedConversationId);

  const currentConversation = conversationsList.find(
    (conversation) => conversation._id === selectedConversationId,
  );

  const handleConversationClick = (conversationId: string) => {
    onChange?.({ conversationId });
  };

  const handleUpdateParticipants = (data: string[]) => {
    replaceConversationParticipants({
      variables: {
        conversationId: currentConversation!._id,
        contacts: data,
      },
      onCompleted: () => {
        refetchConversations();
        setIsEditParticipantsModalVisible(false);
      },
      onError: () => {
        message.error('Error while updating participants, please try again.');
        setIsEditParticipantsModalVisible(false);
      },
    });
  };

  const handleSendMessage: PropType<typeof MessageEditor, 'onOk'> = (
    conversationMessage,
  ) => {
    sendConversationMessage({
      variables: {
        text: conversationMessage.text,
        htmlString: conversationMessage.html,
        attachments: conversationMessage.attachments,
      },
      onError: () => {
        message.error('Error while sending message');
      },
    });
  };

  const currentConversationParticipants = [
    ...(currentConversation?.usersParticipants || []),
    ...(currentConversation?.contactsParticipants || []),
  ];

  if (isInitialLoading) {
    return (
      <StyledConversationsEmptyState>
        <LoadingSpinner />
      </StyledConversationsEmptyState>
    );
  }

  if (totalContactsCount === 0 || conversationsList.length === 0) {
    return (
      <StyledConversationsEmptyState>
        <ConversationEmptyState
          isEmptyConversations={conversationsList.length === 0}
          isEmptyContacts={totalContactsCount === 0}
        />
      </StyledConversationsEmptyState>
    );
  }

  return (
    <StyledConversations $noConversation={!currentConversation}>
      <StyledSearch>
        <SearchInput
          placeholder="Search email"
          onSearch={(text: string) => {
            filterConversationsByMessageOrSubject(text);
          }}
        />
      </StyledSearch>
      <StyledConversationHeader>
        {currentConversation && (
          <ConversationHeader
            subject={currentConversation.subject || ''}
            participants={currentConversationParticipants}
            contactsEditor={
              <HideForViewerRole>
                <AddParticipantsPopover
                  isPopoverVisible={isEditParticipantsModalVisible}
                  isPopoverLoading={loadingReplaceParticipants}
                  primaryRecordId={primaryRecordId}
                  currentParticipants={currentConversation.contactsParticipants}
                  onClose={() => {
                    setIsEditParticipantsModalVisible(false);
                  }}
                  onApplyUpdates={(data) => {
                    handleUpdateParticipants(data);
                  }}
                >
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setIsEditParticipantsModalVisible(true);
                    }}
                  >
                    edit contacts
                  </Button>
                </AddParticipantsPopover>
              </HideForViewerRole>
            }
          >
            <MessagesIssuesQuickView
              conversationId={currentConversation._id}
              onIssueClick={(id) =>
                onChange?.({
                  conversationId: currentConversation._id,
                  messageId: id,
                })
              }
            />
          </ConversationHeader>
        )}
      </StyledConversationHeader>
      <StyledConversationsList>
        <InifiniteScrollList
          list={conversationsList}
          loading={loading}
          hasMoreItems={hasMoreConversationsToFetch}
          loadMoreItems={fetchMoreConversations}
        >
          {(conversation) => {
            const lastConversationMessage = conversation.lastMessage;
            const conversationParticipants = [
              ...(conversation?.usersParticipants || []),
              ...(conversation?.contactsParticipants || []),
            ];

            return (
              <StyledItem
                key={conversation._id}
                onClick={() => {
                  handleConversationClick(conversation._id);
                }}
              >
                <ConversationPreview
                  isActive={selectedConversationId === conversation._id}
                  type={conversation.type}
                  subject={conversation.subject || ''}
                  contacts={conversationParticipants.map(
                    ({ name, email }) => name || email,
                  )}
                  messageText={lastConversationMessage.text}
                  isMessageRead={Boolean(lastConversationMessage.isRead)}
                  messageStatus={lastConversationMessage.status}
                  messageDate={lastConversationMessage.createdAt}
                  messageAuthor={lastConversationMessage.author}
                />
              </StyledItem>
            );
          }}
        </InifiniteScrollList>
      </StyledConversationsList>
      {currentConversation ? (
        <>
          <StyledMessages $isFullHeight={role === ROLES.VIEWER}>
            <Messages
              primaryRecordId={primaryRecordId}
              conversationId={currentConversation._id}
              selectedMessageId={selectedConversation?.messageId}
            />
          </StyledMessages>
          <HideForViewerRole>
            <StyledTextEditor>
              <MessageEditor
                isSendingMessage={loadingSendMessage}
                onOk={handleSendMessage}
              />
            </StyledTextEditor>
          </HideForViewerRole>
        </>
      ) : (
        <StyledNoConversation>
          <ConversationEmptyState
            isEmptyConversations={false}
            isEmptyContacts={false}
          />
        </StyledNoConversation>
      )}
    </StyledConversations>
  );
};

const StyledConversationsEmptyState = styled.section`
  --line-color: ${({ theme }) => theme.colors.lightGray};

  border: 1px solid var(--line-color);
  height: 100%;
  max-height: ${EMAIL_CLIENT_HEIGHT}px;
  border-radius: 8px;
  display: grid;
  place-items: center;
`;

const StyledConversations = styled.section<{ $noConversation: boolean }>`
  --line-color: ${({ theme }) => theme.colors.lightGray};

  height: 100%;
  max-height: ${EMAIL_CLIENT_HEIGHT}px;
  display: grid;
  grid-template-columns: 342px 1fr;
  grid-template-rows: ${EMAIL_CLIENT_HEADER_HEIGHT}px 1fr ${EMAIL_CLIENT_TEXT_EDITOR_HEIGHT}px;
  gap: ${LINE_THICKNESS}px;
  background-color: var(--line-color);
  overflow: hidden;
  grid-template-areas:
    ${({ $noConversation }) =>
      $noConversation
        ? `
          'search messageHeader'
          'messagesList noConversation'
          'messagesList noConversation'
        `
        : `
          'search messageHeader'
          'messagesList message'
          'messagesList textEditor'
        `};
`;

const StyledNoConversation = styled.section`
  grid-area: noConversation;
`;

const StyledSearch = styled.section`
  grid-area: search;
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledConversationHeader = styled.section`
  grid-area: messageHeader;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledConversationsList = styled.section`
  grid-area: messagesList;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${EMAIL_CLIENT_CONVERSATIONS_LIST_HEIGHT}px;
  overflow: hidden;
`;

const StyledMessages = styled.section<{ $isFullHeight: boolean }>`
  grid-area: message;
  position: relative;
  height: ${({ $isFullHeight }) =>
    $isFullHeight
      ? EMAIL_CLIENT_CONVERSATIONS_LIST_HEIGHT
      : EMAIL_CLIENT_MESSAGES_LIST_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledTextEditor = styled.section`
  grid-area: textEditor;
  padding: 16px;
  max-height: ${EMAIL_CLIENT_TEXT_EDITOR_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;
