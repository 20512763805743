import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  DELETE,
  FETCH_ORGANIZATION_MEMBERS,
  FETCH_ORGANIZATION_MEMBER_BY_ID,
  INVITE,
  UPDATE_ORGANIZATION_MEMBER,
} from '../actions';
import { SET_CURRENT_ORGANIZATION_MEMBER } from '../actions/setCurrentOrganizationMember';

export const STATE_KEY = 'organization-member';

const initialState = {
  data: {},
};

const OrganizationMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ORGANIZATION_MEMBERS}_SUCCESS`: {
      const organizationMembers = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', organizationMembers, state);
    }
    case `${FETCH_ORGANIZATION_MEMBER_BY_ID}_SUCCESS`:
    case `${INVITE}_SUCCESS`:
    case `${UPDATE_ORGANIZATION_MEMBER}_SUCCESS`: {
      const organizationMember = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(
        ['data', organizationMember._id],
        organizationMember,
        state,
      );
    }
    case `${DELETE}_SUCCESS`: {
      const organizationMember = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assoc(
        'data',
        R.omit([organizationMember._id], R.path(['data'], state)),
        state,
      );
    }
    case SET_CURRENT_ORGANIZATION_MEMBER: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default OrganizationMemberReducer;
