import { gql } from '@apollo/client';

export const DOCUMENT_TYPE_FRAGMENT = gql`
  fragment DocumentType on DocumentType {
    _id
    name
    pluralName
    description
    isSystemType
    documentsCount
    createdAt
    updatedAt
  }
`;
