import { useAppSelector } from '@store/hooks';
import { getCurrentOrganizationMember } from '../selectors';

const useMemberRole = () => {
  const currentOrganizationMember = useAppSelector(
    getCurrentOrganizationMember,
  );

  return currentOrganizationMember?.role;
};

export default useMemberRole;
