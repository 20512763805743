import moment from 'moment';
import * as R from 'ramda';

import { isSupportedSubjectDateValues } from '@modules/compliance-profile/constants';

/**
 * Get expiration date.
 * @deprecated use getDocumentFirtExpirationDate
 */
export const nextExpirationDate = (document, party) =>
  R.compose(
    R.head,
    R.sort((a, b) => +moment(a).format('X') - +moment(b).format('X')),
    R.reduce((acc, key) => {
      const expirationDate = R.pathOr(
        null,
        [key, 'expirationDate'],
        document.metadata,
      );
      return !R.isNil(expirationDate) ? R.append(expirationDate, acc) : acc;
    }, []),
    R.filter((key) => {
      if (party) {
        const subjects = R.compose(R.uniq, (requirements) =>
          requirements.map((requirement) => requirement.subjectId),
        )(party.requirements || []);

        return subjects.some((subject) => subject === key);
      }
      return true;
    }),
    R.filter(
      (key) =>
        document.metadata[key] &&
        (!isSupportedSubjectDateValues(document.metadata[key].effectiveDate) ||
          !isSupportedSubjectDateValues(document.metadata[key].expirationDate)),
    ),
    R.keys,
  )(document.metadata);
