import { isPaginatedQuery } from '@graphql/utils';

// reference: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
function paginationWithInput() {
  return {
    /**
     * @note this is a configuration for variables with nested objects
     * @link https://github.com/apollographql/apollo-client/issues/7314#issuecomment-726331129
     */
    keyArgs: ['input', ['filter']],
    merge(
      existing: { nodes: any[] },
      incoming: { nodes: any[] },
      context: { args: any; variables?: any },
    ) {
      const { input } = context.args;

      if (isPaginatedQuery(context)) {
        return incoming;
      }

      if (!input?.offset) {
        return incoming;
      }

      const mergedNodes = [
        ...(existing?.nodes || []),
        ...(incoming?.nodes || []),
      ];

      return {
        ...(existing || {}),
        ...(incoming || {}),
        nodes: mergedNodes,
      };
    },
  };
}

export default paginationWithInput;
