import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import styled from 'styled-components';

import IntegrationBadge from '@common/icons/IntegrationBadge';

import getAssociatedProvidersNames from '../utils/getAssociatedProvidersNames';

const ConnectivityBadge = ({ entity, showTitle = false }) => {
  const externalIds = R.propOr([], 'externalIds', entity);

  if (isEmpty(externalIds)) return null;

  return (
    <Tooltip
      title={showTitle ? getAssociatedProvidersNames(externalIds) : null}
    >
      <ConnectivityBadge.Wrapper data-cy="connectivityBadge">
        <IntegrationBadge />
      </ConnectivityBadge.Wrapper>
    </Tooltip>
  );
};

ConnectivityBadge.Wrapper = styled.span`
  padding: 0 5px;
`;

export default ConnectivityBadge;
