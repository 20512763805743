import NotificationsDrawer from '@modules/notification/containers/Notifications';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  isAsideNavigationCollapsed,
  setIsAsideNavigationCollapsed,
} from '@modules/user';

import { ResizeHandler } from '@common/components/ResizeHandler';
import { useResize } from '@common/hooks/useResize';
import { ContextsAddOnFeature } from '@modules/add-on';
import { useCallback, useMemo } from 'react';
import { StyledPageLayoutWithSideNavigationConstants as Constants } from './constants';
import {
  AppOutdatedCardMenu,
  Header,
  NavigationMenu,
  SearchBar,
} from './containers';
import { SettingsMenu } from './containers/SettingsMenu';

type PageLayoutWithSideNavigationProps = {
  organizationCode: string;
  children: React.ReactNode;
};

export const PageLayoutWithSideNavigation = ({
  children,
  organizationCode,
}: PageLayoutWithSideNavigationProps) => {
  const isAsideCollapsed = useSelector(isAsideNavigationCollapsed);
  const dispatch = useDispatch();

  const onToggleSider = useCallback(() => {
    dispatch(setIsAsideNavigationCollapsed(!isAsideCollapsed));
  }, [dispatch, isAsideCollapsed]);

  const minThresholdWatch = useMemo<[number, () => void]>(
    () => [160, onToggleSider],
    [onToggleSider],
  );

  const { handlerRef, resizableRef } = useResize({
    storageKey: 'sideNavigationWidth',
    maxWidth: 400,
    defaultWidth: 250,
    minWidth: 60,
    onClick: onToggleSider,
    minThresholdWatch,
    enabled: !isAsideCollapsed,
    placement: 'right',
  });

  return (
    <StyledPageLayout>
      <StyledLayoutSiderResizer ref={resizableRef}>
        <StyledLayoutSider
          collapsedWidth="60"
          collapsed={isAsideCollapsed}
          onCollapse={onToggleSider}
          data-cy="mainMenu"
          width="100%"
        >
          <StyledSettingsMenuWrapper>
            <SettingsMenu isMinifiedMode={isAsideCollapsed} />
          </StyledSettingsMenuWrapper>
          <StyledSearcWrapper>
            <SearchBar isMinifiedMode={isAsideCollapsed} />
          </StyledSearcWrapper>
          <NavigationMenu organizationCode={organizationCode} />
          <AppOutdatedCardMenu
            isMinifiedMode={isAsideCollapsed}
            onClick={onToggleSider}
          />
        </StyledLayoutSider>
      </StyledLayoutSiderResizer>

      <ResizeHandler
        ref={handlerRef}
        onToggle={onToggleSider}
        toggleDirection={isAsideCollapsed ? 'right' : 'left'}
      />

      <Layout>
        <ContextsAddOnFeature>
          <Header />
        </ContextsAddOnFeature>
        <StyledContent>{children}</StyledContent>
      </Layout>
      <NotificationsDrawer />
    </StyledPageLayout>
  );
};

const StyledPageLayout = styled(Layout)`

  height: 100vh;
  overflow: hidden;
`;

const StyledLayoutSider = styled(Layout.Sider)`
  background-color: transparent !important;
  transition: width 200ms !important;

  .ant-menu-item {
    padding: 0 ${Constants.spacingMd};
    margin: 0 0 4px;
    width: 100%;
    height: 30px;
  }

  .ant-menu-item-active {
    background-color: rgba(0, 0, 0, 0.06);
  }

  &.ant-layout-sider-collapsed {
    .ant-menu-item {
      padding: 0 ${Constants.spacingSm};
    }
  }
`;

const StyledLayoutSiderResizer = styled.div`
  height: 100%;
  background-color: #fafafa;
  border-right: 1px solid rgba(196, 214, 236, 0.5);

  & > * {
    height: 100%;
  }
`;

const StyledSettingsMenuWrapper = styled.section`
  padding: 16px ${Constants.spacingMd};
  height: 64px;
`;

const StyledSearcWrapper = styled.section`
  padding: 0 ${Constants.spacingMd} ${Constants.spacingSm};
`;

const StyledContent = styled(Layout.Content)`
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;
