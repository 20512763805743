import { Button, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  CellActions,
  type ColDef,
  type CustomSelectionChangedEvent,
  type SortDirection,
  Table,
  type TableRef,
  type TextFilter,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import { downloadFile } from '@common/utils/document-helpers';
import type { ContextRecordAttachment } from '@graphql/types/graphql';
import { HideForViewerRole } from '@modules/organization-member';
import { UploadAttachmentModal } from '../UploadAttachmentModal';
import { getDeleteFilters } from './AttachmentsTable.utils';
import { COLUMN_FIELDS } from './constants';
import { DeleteAttachmentModal } from './containers/DeleteAttachmentModal';
import { DeleteAttachmentsModal } from './containers/DeleteAttachmentsModal';
import { useContextRecordAttachments } from './hooks/useContextRecordAttachments';
import { useSignedUrl } from './hooks/useSignedUrl';

export type TableFiltersModel = {
  [COLUMN_FIELDS.name]?: TextFilter;
};

export type AttachmentsTableState = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
  viewId?: string;
};

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedAttachmentS3Keys: string[];
  selectedRowsData: any[];
};

type AttachmentsTableProps = {
  recordId: string;
};

export const AttachmentsTable = ({ recordId }: AttachmentsTableProps) => {
  const tableRef = useRef<TableRef>(null);

  const [inputSearchValue, setInputSearchValue] = useState('');
  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);
  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
  });
  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedAttachmentS3Keys: [],
    selectedRowsData: [],
  });
  const [openUploadAttachmentModal, setOpenUploadAttachmentModal] =
    useState(false);
  const [bulkDeleteModalState, setBulkDeleteModalState] = useSetState<{
    visible: boolean;
  }>({
    visible: false,
  });
  const [singleDeleteModalState, setSingleDeleteModalState] = useSetState<{
    visible: boolean;
    s3Key: string;
    friendlyName: string;
  }>({
    visible: false,
    s3Key: '',
    friendlyName: '',
  });

  const { getAttachments } = useContextRecordAttachments({
    contextRecordId: recordId,
  });
  const { signUrl, loading: loadingSignUrl } = useSignedUrl();

  const columns: ColDef<ContextRecordAttachment & { actions: unknown }>[] =
    useMemo(
      () => [
        {
          headerName: 'Name',
          field: COLUMN_FIELDS.name,
          sortable: false,
          filter: 'agTextColumnFilter',
          cellDataType: 'text',
          flex: 1,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: '',
          field: COLUMN_FIELDS.actions,
          lockPosition: 'right' as const,
          pinned: 'right' as const,
          width: 90,
          maxWidth: 100,
          sortable: false,
          valueGetter: ({ data }: { data: any }) => data,
          //eslint-disable-next-line
          cellRenderer: ({ value: data }: { value: any }) => (
            <CellActions>
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label: 'Download',
                      code: 'download',
                      disabled: loadingSignUrl,
                      onClick: async () => {
                        const signedUrl = await signUrl({
                          variables: {
                            s3Key: data.s3Key,
                          },
                        });
                        downloadFile(
                          signedUrl.data?.signedUrl,
                          data.friendlyName,
                        );
                      },
                    },
                    {
                      label: 'Delete',
                      code: 'delete',
                      disabled: false,
                      alert: true,
                      onClick: () => {
                        setSingleDeleteModalState({
                          visible: true,
                          friendlyName: data.friendlyName,
                          s3Key: data.s3Key,
                        });
                      },
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          ),
        },
      ],
      [loadingSignUrl, signUrl, setSingleDeleteModalState],
    );

  const handleSelectionChange = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedAttachmentS3Keys: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleSelectVisibleItems = () => {
    tableRef?.current?.selectVisibleRows();
  };

  const resetItemsTableSelection = () => {
    tableRef?.current?.resetSelection();
    setCounters({ selectedRows: 0 });
    setSelection({
      isSelectAllChecked: false,
      selectedAttachmentS3Keys: [],
    });
  };

  const handleSelectAllItems = () => {
    tableRef?.current?.selectAllRows();
  };

  const handleDelete = () => {
    setBulkDeleteModalState({ visible: true });
  };

  const handleCloseUploadAttachmentModal = () => {
    setOpenUploadAttachmentModal(false);
  };

  const handleCompleteUploadAttachmentModal = () => {
    setOpenUploadAttachmentModal(false);
    tableRef?.current?.refreshTable();
  };

  return (
    <>
      <UploadAttachmentModal
        open={openUploadAttachmentModal}
        contextRecordId={recordId}
        onCancel={handleCloseUploadAttachmentModal}
        onCompleted={handleCompleteUploadAttachmentModal}
      />
      <DeleteAttachmentsModal
        open={bulkDeleteModalState.visible}
        count={counters.selectedRows}
        filters={getDeleteFilters({
          s3Keys: selection.selectedAttachmentS3Keys,
          textValue: inputSearchValue,
        })}
        recordId={recordId}
        onCompleted={() => {
          setBulkDeleteModalState({ visible: false });
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onCancel={() => {
          setBulkDeleteModalState({ visible: false });
        }}
      />
      <DeleteAttachmentModal
        open={singleDeleteModalState.visible}
        recordId={recordId}
        attachment={singleDeleteModalState}
        onCompleted={() => {
          setSingleDeleteModalState({ visible: false });
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onCancel={() => setSingleDeleteModalState({ visible: false })}
      />
      <StyledWrapper>
        <TableHeader>
          <TableHeader.BottomRightSection>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setOpenUploadAttachmentModal(true)}
            >
              Attachment
            </Button>
          </TableHeader.BottomRightSection>

          <TableHeader.BottomLeftSection>
            {areBulkActionsButtonsVisible ? (
              <Space>
                <SelectionDropdown
                  areAllSelected={selection.isSelectAllChecked}
                  selectedEntitiesCount={counters.selectedRows}
                  onSelectAll={handleSelectAllItems}
                  onSelectAllVisible={handleSelectVisibleItems}
                  onSelectNone={resetItemsTableSelection}
                  totalCount={counters.totalRows}
                  totalVisible={counters.visibleRows}
                />

                <Button icon={<DeleteOutlined />} onClick={handleDelete}>
                  Delete
                </Button>
              </Space>
            ) : (
              <StyledSearchInput
                onChange={(e) => setInputSearchValue(e.target.value)}
                defaultValue={inputSearchValue}
                onSearch={(value) => {
                  tableRef.current?.setFilters({
                    [COLUMN_FIELDS.name]: {
                      filterType: 'text',
                      filter: value,
                      type: 'contains',
                    },
                  });
                }}
              />
            )}
          </TableHeader.BottomLeftSection>
        </TableHeader>
        <StyledTable>
          <Table
            ref={tableRef}
            rowIdKey={COLUMN_FIELDS.s3Key}
            isSideBarDisabled
            columnDefs={columns}
            getRowData={getAttachments}
            onSelectionChanged={handleSelectionChange}
          />
        </StyledTable>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  flex: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 200px;
`;
