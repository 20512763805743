import type React from 'react';
import styled from 'styled-components';

type ListPageTableWrapperProps = {
  children?: React.ReactNode;
  className?: string;
};

const ListPageTableWrapper = (props: ListPageTableWrapperProps) => (
  <StyledWrapper {...props} />
);

const StyledWrapper = styled.div`
  padding: 20px 0;

  .ant-table-wrapper {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #e9f1fb;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(71, 88, 114, 0.14);
  }

  .ant-table-thead {
    position: sticky;
    top: 69px;
    background: rgb(255, 255, 255);
    z-index: 1;
  }

  .ant-spin-blur {
    overflow: inherit;
  }

  table {
    background-color: inherit;
  }

  th {
    background-color: #f9fbfe !important;
    padding: 8px 4px !important;
  }

  .ant-table-column-sorter-full {
    visibility: hidden;
  }

  .ant-table-column-sort {
    .ant-table-column-sorter-full {
      visibility: visible;
    }
  }

  th:hover {
    .ant-table-column-sorter-full {
      visibility: visible;
    }
  }

  td {
    padding: 8px 4px !important;
  }

  th:first-child,
  td:first-child {
    padding: 8px 10px 8px 15px !important;
  }

  .ant-table-column-sorters {
    padding: 0;

    > span:empty {
      display: none;
    }
  }
`;

export default ListPageTableWrapper;
