import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
  justify-content: space-between;
`;

export default Column;
