import type { SubscriptionHookOptions } from '@apollo/client';

import { useSubscription } from '@graphql/hooks';
import { getCurrentOrganizationMember } from '@modules/organization-member/selectors';
import { useAppSelector } from '@store/hooks';

import { NOTIFICATION_UPDATES_SUBSCRIPTION } from '../subscriptions';

const useNotificationUpdatesSubscription = (
  options: SubscriptionHookOptions,
) => {
  const currentOrganizationMember = useAppSelector(
    getCurrentOrganizationMember,
  );

  return useSubscription(NOTIFICATION_UPDATES_SUBSCRIPTION, {
    variables: { memberId: currentOrganizationMember?._id },
    ...options,
  });
};

export default useNotificationUpdatesSubscription;
