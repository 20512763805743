import { namespace } from '../constants';

export const SET_CURRENT_ORGANIZATION_MEMBER = `${namespace}/SET_CURRENT_ORGANIZATION_MEMBER`;

export const setCurrentOrganizationMember = (payload?: {
  _id: string;
  role: string;
}) => ({
  type: SET_CURRENT_ORGANIZATION_MEMBER,
  payload,
});
