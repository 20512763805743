import { getExtension, getType } from 'mime';
import PSPDFKitWeb from 'pspdfkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import SSLCertificates from '@common/SSLCertificates';
import Spinner, { SpinnerType } from '@common/components/Spinner';
import PspdfkitConfig from '@config/PspdfkitConfig';
import { getInstantJSON } from '@modules/document/utils/document-helpers';
import { FILLABLE_FORM_STATUS } from '@modules/fillable-form/constants';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';

import { saveAs } from '@common/utils/document-helpers';
import { useDocumentInstance } from './hooks';

PSPDFKitWeb.Options.IGNORE_DOCUMENT_PERMISSIONS = true;

const PdfReader = ({
  document,
  filterToolbarItems = (e) => e,
  annotationTooltipCallback = () => [],
}) => {
  const [fallback, setFallback] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const container = useRef(null);

  const { documentInstance, setDocumentInstance } = useDocumentInstance();
  const memberRole = useMemberRole();

  /*
  Set instance as a ref to be used ONLY for annotationTooltipCallback,
  since there is no other way to use the documentInstance from within that callback (hopefully it will be passed from the SDK in a future relase).

  For all the other needs you MUST use documentInstance!!
  */
  const documentInstanceRef = useRef();
  documentInstanceRef.current = documentInstance;

  const documentId = document?._id;
  const friendlyName = document?.friendlyName;
  const documentStatus = document?.fillableForm?.status;

  // Default config for reader.
  useEffect(() => {
    if (documentInstance) {
      // Set "Fit to width" as default.
      documentInstance.setViewState((viewState) => {
        const newState = viewState.set(
          'zoom',
          PSPDFKitWeb.ZoomMode.FIT_TO_WIDTH,
        );
        return newState.set(
          'showSignatureValidationStatus',
          PSPDFKitWeb.ShowSignatureValidationStatusMode.IF_SIGNED,
        );
      });

      documentInstance.getSignaturesInfo().then(console.log);
    }
  }, [documentInstance]);

  // Download document
  useEffect(() => {
    if (isDownload && documentInstance) {
      const fileExtension = getExtension(getType(document?.s3Key));

      documentInstance
        .exportPDF({
          flatten: documentStatus !== FILLABLE_FORM_STATUS.SIGNED,
          incremental: documentStatus === FILLABLE_FORM_STATUS.SIGNED,
        })
        .then((buffer) => {
          saveAs(new Blob([buffer]), `${friendlyName}.${fileExtension}`);
          setIsDownload(false);
        });
    }
  }, [
    friendlyName,
    documentInstance,
    isDownload,
    documentStatus,
    document?.s3Key,
  ]);

  // Get toolbar items.
  const getToolbarItems = useCallback(() => {
    const items = PSPDFKitWeb.defaultToolbarItems.filter(
      (item) => item.type !== 'print',
    );
    const downloadButton = {
      type: 'custom',
      id: 'download-document',
      icon: '/images/download.svg',
      title: 'Download',
      dropdownGroup: 'actionsGroup',
      onPress: () => {
        setIsDownload(true);
      },
    };

    const printButton = {
      type: 'print',
      dropdownGroup: 'actionsGroup',
    };

    const hideButtonsTypes = [
      'ink-signature',
      'signature',
      'image',
      'export-pdf',
      'document-editor',
      'document-crop',
    ];

    const drawingGroupTypes = [
      'ink',
      'highlighter',
      'text-highlighter',
      'ink-eraser',
      'stamp',
    ];

    if (document?.fillableForm?._id) {
      hideButtonsTypes.push(
        ...[
          ...drawingGroupTypes,
          'annotate',
          'text',
          'line',
          'note',
          'arrow',
          'rectangle',
          'ellipse',
          'polygon',
          'polyline',
          'multi-annotations-selection',
          'cloudy-polygon',
          'link',
        ],
      );
    }

    const defaultItems = items
      .concat(downloadButton, printButton)
      .filter((item) => !hideButtonsTypes.includes(item.type))
      .map((item) => {
        if (drawingGroupTypes.includes(item.type)) {
          return {
            ...item,
            dropdownGroup: 'drawingGroup',
          };
        }

        if (memberRole === ROLES.VIEWER && item.type === 'annotate') {
          return {
            ...item,
            disabled: true,
          };
        }

        return item;
      });

    return filterToolbarItems(defaultItems);
  }, [document, filterToolbarItems, memberRole]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const abortController = new AbortController();

    const signal = abortController.signal;

    const load = async () => {
      const data = await fetch(document.url, { signal })
        .then((res) => res.arrayBuffer())
        .then((data) => {
          return data;
        })
        .catch((err) => {
          console.error(err);
          return;
        });

      if (!data || data.byteLength === 0) {
        return;
      }

      if (document?._id !== currentDocumentId || !document?._id) {
        try {
          const instance = await PSPDFKitWeb.load({
            ...PspdfkitConfig,
            document: data,
            container: container.current,
            styleSheets: ['/styles/pspdfkit.0002.css'],
            instantJSON: getInstantJSON(document),
            toolbarItems: getToolbarItems(),
            isAPStreamRendered: () => false,
            annotationTooltipCallback: (annotation) =>
              annotationTooltipCallback(
                annotation,
                documentInstanceRef.current,
              ),
            inlineTextSelectionToolbarItems: ({ defaultItems }) => {
              return document?.fillableForm?._id ? [] : defaultItems;
            },
            trustedCAsCallback: async () => SSLCertificates,
          }).catch((error) => {
            console.log(error);
            setFallback(true);
          });

          setDocumentInstance(instance);
          setCurrentDocumentId(document._id);
        } catch (e) {
          console.log(e);
          setFallback(true);
        }
      }
    };

    if (container.current && document?._id) {
      setIsLoading(true);
      load().finally(() => {
        setIsLoading(false);
      });
    }

    return () => {
      abortController.abort();
    };
  }, [documentId]);

  useEffect(() => {
    return () => {
      if (documentInstance) {
        // wrapping in try catch to avoid possible errors sent to Sentry
        // https://trustlayer-inc.sentry.io/share/issue/c16f4dd1309c4165a84191fb430abba7/
        try {
          PSPDFKitWeb.unload(documentInstance);
        } catch (e) {
          console.warn('Unloading of PSDPDfKit instance failed');
        }
      }
    };
  }, [documentInstance]);

  return fallback ? (
    <object
      data={document.url}
      type="application/pdf"
      width="100%"
      height="100%"
    />
  ) : (
    <StyledWrapper>
      <StyledWrapper ref={container} />
      {isLoading && (
        <StyledLoadingOverlay data-cy="pdfReaderLoadingOverlay">
          <Spinner style={SpinnerType.DarkBackground} />
        </StyledLoadingOverlay>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledLoadingOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PdfReader;
