import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const UPDATE_DOCUMENT_ANNOTATIONS = `${namespace}/UPDATE_DOCUMENT_ANNOTATIONS`;

const UPDATE_DOCUMENT_ANNOTATIONS_MUTATION = gql`
  mutation updateDocumentAnnotations($data: DocumentAnnotationsInput!) {
    updateDocumentAnnotations(data: $data) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const updateDocumentAnnotations = (data, notUpdateStore) => ({
  type: UPDATE_DOCUMENT_ANNOTATIONS,
  payload: {
    key: 'updateDocumentAnnotations',
    notUpdateStore,
    graphql: {
      mutation: UPDATE_DOCUMENT_ANNOTATIONS_MUTATION,
      variables: { data },
    },
  },
});
