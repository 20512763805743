import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { getGraphqlPayload, getNewGraphqlPayload } from '@store/helpers';

import {
  DELETE_CONVERSATION_STATE,
  FETCH_PARTY_CONVERSATIONS,
  GET_NEW_MESSAGES_COUNT,
  GET_PARTY_CONVERSATION_MESSAGES_SUCCESS,
  MARK_AS_READ_MESSAGES,
  UPDATE_CONVERSATION_STATE,
  UPDATE_PARTY_CONVERSATION,
} from '../actions';

const initialState = {
  data: {},
  newMessagesCount: 0,
};

const PartyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PARTY_CONVERSATIONS}_SUCCESS`: {
      const partyConversations = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', partyConversations, state);
    }
    case UPDATE_CONVERSATION_STATE: {
      const partyConversation = R.pathOr(
        {},
        ['data', 'conversationUpdates', 'partyConversation'],
        action.payload,
      );

      if (isEmpty(partyConversation.messages?.nodes)) {
        return R.dissocPath(['data', partyConversation._id], state);
      }
      return R.assocPath(
        ['data', partyConversation._id],
        partyConversation,
        state,
      );
    }
    case DELETE_CONVERSATION_STATE: {
      const deletedPartyConversation = R.pathOr(
        {},
        ['data', 'conversationUpdates', 'deletedPartyConversation'],
        action.payload,
      );

      return R.dissocPath(['data', deletedPartyConversation._id], state);
    }
    case `${UPDATE_PARTY_CONVERSATION}_SUCCESS`: {
      const partyConversation = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return {
        ...state,
        data: {
          ...state.data,
          [partyConversation._id]: {
            ...state.data[partyConversation._id],
            ...partyConversation,
          },
        },
      };
    }
    case `${GET_NEW_MESSAGES_COUNT}_SUCCESS`:
    case `${MARK_AS_READ_MESSAGES}_SUCCESS`: {
      const newMessagesCount = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assoc('newMessagesCount', newMessagesCount, state);
    }
    case GET_PARTY_CONVERSATION_MESSAGES_SUCCESS:
      const {
        data: { _id, messages },
      } = getNewGraphqlPayload(action);

      return R.assocPath(['data', _id, 'messages'], messages, state);
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default PartyTypeReducer;
