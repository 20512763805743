import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import type { CreateRequestRecordsMutation } from '@graphql/types/graphql';
import { RECORD_TYPES, type RecordTypes } from '@trustlayer/common';
import { DEFAULT_CONTEXT } from '../containers/CreateRequestsModal/constants';

type CreateRequestRecordsParams = {
  requestRecords: {
    name: string;
    related: string;
    profile?: string;
  }[];
  onCompleted: (data: CreateRequestRecordsMutation) => void;
};

type PivotalRecordTypes = Exclude<RecordTypes, typeof RECORD_TYPES.Request>;

const RECORD_TYPE_CONFIG = {
  [RECORD_TYPES.Primary]: {
    primaryRecordSource: true,
    contextRecordSource: false,
  },
  [RECORD_TYPES.Context]: {
    primaryRecordSource: false,
    contextRecordSource: true,
  },
};

const CREATE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation CreateRequestRecords($data: CreateRequestRecordsInput!) {
    createRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useCreateRequestRecordsMutation = (
  source: PivotalRecordTypes,
  recordId: string,
) => {
  const [createRequestRecordsMutation, { loading }] = useMutation(
    CREATE_REQUEST_RECORDS_MUTATION,
  );

  const createRequestRecords = ({
    requestRecords,
    onCompleted,
  }: CreateRequestRecordsParams) => {
    createRequestRecordsMutation({
      variables: {
        data: {
          requestRecords: mapClientRequestToMutationRequest(
            requestRecords,
            recordId,
            source,
          ),
        },
      },
      onCompleted,
    });
  };

  return {
    createRequestRecords,
    loading,
  };
};

const mapClientRequestToMutationRequest = (
  requests: CreateRequestRecordsParams['requestRecords'],
  recordId: string,
  source: PivotalRecordTypes,
) => {
  return requests.map((request) => {
    const primaryRecordId = RECORD_TYPE_CONFIG[source].primaryRecordSource
      ? recordId
      : request.related;
    const contextRecordId = RECORD_TYPE_CONFIG[source].contextRecordSource
      ? recordId
      : request.related;

    return {
      name: request.name,
      primaryRecordId,
      ...(contextRecordId !== DEFAULT_CONTEXT._id && { contextRecordId }),
      ...(request.profile && { complianceProfileId: request.profile }),
    };
  });
};
