export const MERGE_TYPE_VARIABLE = {
  PAGINATED: 'paginated',
  INFINITE: 'infinite',
};

export type MergeTypeVariables = {
  mergeType?:
    | typeof MERGE_TYPE_VARIABLE.PAGINATED
    | typeof MERGE_TYPE_VARIABLE.INFINITE;
};
