import type { ReactNode } from 'react';
import styled from 'styled-components';

type DisableProps = {
  isDisabled?: boolean;
  children: ReactNode;
  className?: string;
};

const StyledDisableWrapper = styled.div`
  opacity: 0.25;
  pointer-events: none;
`;

const Disable = ({ isDisabled, children }: DisableProps) => {
  if (isDisabled) {
    return <StyledDisableWrapper>{children}</StyledDisableWrapper>;
  }

  return <>{children}</>;
};

export default Disable;
