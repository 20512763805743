import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';
import { updateEventLogState } from '@modules/event-log/actions';
import { FeatureFlagsMap, isFeatureFlagEnabled } from '@modules/feature-flags';
import { FILLABLE_FORM_STATUS } from '@modules/fillable-form/constants';
import {
  setBulkOperationCompleted,
  setIsReloadPage,
} from '@modules/organization/actions';
import { getActiveOrganizationId } from '@modules/organization/selectors';
import { updatePartyComplianceProfileState } from '@modules/party-compliance-profile/actions';
import {
  fetchPartyWithDetails,
  updatePartyState,
} from '@modules/party/actions';
import { getParty } from '@modules/party/selectors';
import { updateRequirementsState } from '@modules/requirement/actions';
import { push } from '@modules/router/actions';
import { setProjectAsCurrent } from '@modules/system-settings/actions';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import {
  ADD_BULK_DOCUMENT_TYPES,
  ARCHIVE_DOCUMENT,
  ARCHIVE_DOCUMENTS,
  CANCEL_DOCUMENTS_UPLOADING,
  DELETE_DOCUMENT,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  PROCESS_DOCUMENT,
  PROCESS_DOCUMENTS_FILES,
  PROCESS_DOCUMENT_FILE,
  REMOVE_BULK_DOCUMENT_TYPES,
  REVIEW_DOCUMENTS,
  RUN_DELETE_DOCUMENT,
  SUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED,
  UNSUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED,
  UPDATE_DOCUMENT_STATE,
  addSubscriptionsIds,
  deleteDocument,
  deleteDocuments,
  processDocument,
  setDocumentFillableFormState,
  setIsUploadingDocuments,
  updateDocumentState,
} from '../actions';
import {
  EventType,
  getBulkEventAnalyticsMessage,
  getBulkEventDefaultMessage,
} from '../constants';
import { getDocument, getSubscriptions } from '../selectors';

const DOCUMENT_PROCESSED_SUBSCRIPTION = gql`
  subscription onDocumentProcessed($partyId: String!) {
    documentProcessed(partyId: $partyId)
  }
`;

const BULK_DOCUMENT_ACTIONS_PROCESSED_SUBSCRIPTION = gql`
  subscription onBulkDocumentsProcessed($organizationId: String!) {
    bulkDocumentsProcessed(organizationId: $organizationId)
  }
`;

/**
 * Update state after processed document
 */
function* updateStateAfterProcessedDocument(payload) {
  const type = R.pathOr('', ['data', 'documentProcessed', 'type'], payload);

  switch (type) {
    case EventType.documentEmptyExtractedData:
    case EventType.documentProcessed: {
      yield put(updateDocumentState(payload));
      break;
    }
    case EventType.partyComplianceUpdate: {
      yield put(updatePartyState(payload));
      break;
    }
    case EventType.eventLogUpdate: {
      yield put(updateEventLogState(payload));
      break;
    }
    case EventType.requirementsUpdate: {
      yield put(updateRequirementsState(payload));
      break;
    }
    case EventType.partyComplianceProfileUpdate: {
      yield put(updatePartyComplianceProfileState(payload));
      break;
    }
    case EventType.documentSignedSuccess:
    case EventType.documentSigning: {
      const partyId = payload?.data?.documentProcessed?.party?._id;
      const documentId = payload?.data?.documentProcessed?.document?._id;

      if (!window.location.pathname.includes(partyId)) {
        yield cancel();
      }

      const formStatus =
        EventType.documentSigning === type
          ? FILLABLE_FORM_STATUS.SIGNING
          : FILLABLE_FORM_STATUS.SIGNED;

      yield put(
        setDocumentFillableFormState({
          documentId,
          status: formStatus,
        }),
      );
      break;
    }
    default:
      return;
  }
}

/**
 * Processed document listener
 */
export function* subscribeToDocumentsSaga(partyId) {
  try {
    const documentChannel = yield call(() =>
      eventChannel((emit) => {
        const subscription = graphqlClient
          .subscribe({
            query: DOCUMENT_PROCESSED_SUBSCRIPTION,
            variables: {
              partyId,
            },
          })
          .subscribe({
            next(data) {
              emit(data);
            },
          });

        const unsubscribe = () => {
          subscription.unsubscribe();
        };

        return unsubscribe;
      }),
    );

    yield takeLatest(
      UNSUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED,
      function (cancelAction) {
        if (cancelAction.payload === partyId) {
          documentChannel.close();
        }
      },
    );

    // eslint-disable-next-line fp/no-loops
    while (true) {
      try {
        const payload = yield take(documentChannel);

        yield fork(updateStateAfterProcessedDocument, payload);
      } catch (err) {
        console.error('Socket error:', err);
      }
    }
  } catch (err) {
    console.error('Saga error:', err);
  } finally {
    if (yield cancelled()) {
      yield fork(subscribeToDocumentsSaga, partyId);
    }
  }
}

/**
 * Upload file
 */
function* processDocumentFileSaga({ payload }) {
  const partyId = R.propOr(null, 'party', payload);
  // FIXME: The user can upload files without assigning them to a party from the Documents Library.
  // This saga handles both document assigned to a party and document that are not.
  // We should find a better way to handle this, and add tests.
  const activeOrganization = yield select(getActiveOrganizationId);
  const party = yield select(getParty, partyId);
  const organization = party ? party.organization : activeOrganization;

  const res = yield putResolve(processDocument({ ...payload, organization }));
  const document = R.pathOr(
    {},
    ['payload', 'data', 'processDocumentFile'],
    res,
  );
  const projectName = R.path(['projects', '0', 'name'], document);
  yield put(setIsReloadPage(true));

  if (projectName) {
    yield call(trackEvent, 'User uploaded a document to a project');
  } else {
    yield call(trackEvent, 'User uploaded a document');
  }
  yield notify.info(`Document successfully uploaded and being processed.`);
}

/**
 * Bulk upload files
 */
function* processDocumentsFilesSaga({ payload: { data, files } }) {
  const documentIds = [];

  try {
    const partyId = R.propOr(null, 'party', data);
    const activeOrganization = yield select(getActiveOrganizationId);
    const party = yield select(getParty, partyId);
    const organization = party ? party.organization : activeOrganization;

    yield put(setIsUploadingDocuments(true));
    // eslint-disable-next-line fp/no-loops
    for (const file of files) {
      const res = yield putResolve(
        processDocument({ ...data, organization, file }),
      );
      documentIds.push(R.prop('_id', getGraphqlPayload(res)));
    }
    yield put(setIsUploadingDocuments(false));
    yield put(setIsReloadPage(true));
  } finally {
    if (yield cancelled()) {
      const lastResult = yield take(`${PROCESS_DOCUMENT}_SUCCESS`);
      documentIds.push(R.prop('_id', getGraphqlPayload(lastResult)));
      yield put(deleteDocuments({ documentIds }));
      yield put(setIsUploadingDocuments(false));
    }
  }
}

/**
 * Notify when the reviewDocuments succeed
 */
function* reviewDocumentsSuccessSaga(action) {
  const reviewedDocuments = getGraphqlPayload(action);
  const isReviewed = Boolean(reviewedDocuments[0]?.reviewedAt);
  yield notify.success(
    `${pluralize('document', reviewedDocuments.length, true)} marked as ${
      isReviewed ? 'reviewed' : 'to review'
    }`,
  );
}

/**
 * Notify on addBulkDocumentTypes success
 */
function* addOrRemoveBulkDocumentTypesSuccessSaga(action) {
  const documentsUpdated = getGraphqlPayload(action);
  yield notify.success(
    `${pluralize(
      'document',
      documentsUpdated.length,
      true,
    )} successfully updated.`,
  );
}

/**
 * Useful only until we will have a subscription to deal with deleted rpojects by other users
 */
function* onFetchDocumentsFailSaga(payload) {
  const { error } = payload;

  const hasSelectedProjectNotFoundError = error?.some((err) =>
    err?.message?.startsWith('Missing project'),
  );

  if (hasSelectedProjectNotFoundError) {
    yield notify.error('Selected project is not available.');
    yield put(setProjectAsCurrent(null));
  }
}

function* onFetchDocumentFailSaga(payload) {
  const error = payload?.error?.[0];

  if (String(error?.extensions?.applicationCode) === 'DOC001') {
    console.warn('Resource not found. Redirecting to 404...');
    yield put(push('/404'));
    return;
  }
}

/**
 * Show notification after delete document
 */
function* deleteNotificationSaga() {
  yield notify.success(`Document successfully deleted`);
}

/**
 * Show notification after delete document
 */
function* processDocumentNotificationSaga() {
  yield notify.success(`Document successfully processed`);
}

/**
 * Delete document saga
 */
function* deleteDocumentSaga({ payload }) {
  const document = yield select(getDocument, payload);

  yield putResolve(deleteDocument(payload));

  if (document.party) {
    yield putResolve(fetchPartyWithDetails(R.path(['party', '_id'], document)));
  }
}

/**
 * Archive document
 */
function* archiveDocumentSuccessSaga({ payload }) {
  const document = R.pathOr(null, ['data', 'archiveDocument'], payload);
  const partyId = R.pathOr(null, ['party', '_id'], document);

  if (partyId) {
    yield putResolve(fetchPartyWithDetails(partyId));
  }
}

function* updateSinglePartySubscription({ payload: partyId }) {
  const existingSubscriptions = yield select(getSubscriptions);
  const isAlreadySubscribed = existingSubscriptions.includes(partyId);

  if (isAlreadySubscribed) {
    return;
  }

  yield fork(subscribeToDocumentsSaga, partyId);
  yield put(addSubscriptionsIds([partyId]));
}

function* startUploadDocumentsSaga(payload) {
  const task = yield fork(processDocumentsFilesSaga, payload);
  yield take(CANCEL_DOCUMENTS_UPLOADING);
  yield cancel(task);
}

/**
 * Archive documents
 */
export function* arhiveDocumentsSuccessSaga(action) {
  const documents = getGraphqlPayload(action);
  const parties = R.compose(R.uniq, (docs) =>
    docs.map((document) => R.path(['party', '_id'], document)),
  )(documents);

  if (!isEmpty(parties)) {
    yield all(parties.map((partyId) => put(fetchPartyWithDetails(partyId))));
  }
}

function* subscribeBulkDocumentsSaga() {
  const organization = yield select(getActiveOrganizationId);
  /**
   * @note it should be removed with useTrustLayerV2FeatureFlag,
   * BUT before: this subscription should be refactored and moved putside sagaJs
   */
  const isTlV2FeatureFlagEnabled = yield select((state) =>
    isFeatureFlagEnabled(state, FeatureFlagsMap.TL_V2),
  );

  if (isTlV2FeatureFlagEnabled) {
    return;
  }

  if (organization) {
    yield fork(subscribeToBulkDocumentsSaga, organization);
  }
}

export function* subscribeToBulkDocumentsSaga(organizationId) {
  const bulkDocumentsChannel = yield call(() =>
    eventChannel((emit) => {
      const subscription = graphqlClient
        .subscribe({
          query: BULK_DOCUMENT_ACTIONS_PROCESSED_SUBSCRIPTION,
          variables: {
            organizationId,
          },
        })
        .subscribe({
          next(data) {
            emit(data);
          },
        });

      const unsubscribe = () => {
        subscription.unsubscribe();
      };

      return unsubscribe;
    }),
  );
  // eslint-disable-next-line fp/no-loops
  while (true) {
    try {
      const payload = yield take(bulkDocumentsChannel);
      yield fork(updateStateBulkDocumentsSubscription, payload);
    } catch (err) {
      console.error('Socket error:', err);
    }
  }
}

function* updateStateBulkDocumentsSubscription(payload) {
  const type = R.pathOr(
    '',
    ['data', 'bulkDocumentsProcessed', 'type'],
    payload,
  );
  const result = R.pathOr(
    {},
    ['data', 'bulkDocumentsProcessed', 'data'],
    payload,
  );

  switch (type) {
    case EventType.bulkArchiveDocumentSuccess:
    case EventType.bulkAssignDocumentsToPartySuccess:
    case EventType.bulkDetachDocumentsFromPartySuccess:
    case EventType.bulkAssociateDocumentsToProjectSuccess:
    case EventType.bulkDetachDocumentsFromProjectSuccess:
    case EventType.bulkReviewDocumentsSuccess:
    case EventType.bulkAddRequirementValueSuccess:
    case EventType.bulkMatchRequirementsSuccess:
    case EventType.bulkMatchComplianceSuccess:
    case EventType.bulkDeleteDocumentsSuccess: {
      yield put(
        setBulkOperationCompleted({ status: true, entity: 'documents' }),
      );
      yield notify.success(
        result.message || getBulkEventDefaultMessage({ type, result }),
      );
      yield call(trackEvent, getBulkEventAnalyticsMessage({ type, result }));
      break;
    }
    case EventType.bulkArchiveDocumentFail:
    case EventType.bulkAssignDocumentsToPartyFail:
    case EventType.bulkDetachDocumentsFromPartyFail:
    case EventType.bulkAssociateDocumentsToProjectFail:
    case EventType.bulkDetachDocumentsFromProjectFail:
    case EventType.bulkReviewDocumentsFail:
    case EventType.bulkAddRequirementValueFail:
    case EventType.bulkMatchRequirementsFail:
    case EventType.bulkMatchComplianceFail:
    case EventType.bulkDeleteDocumentsFail: {
      yield notify.error(result.message);
      break;
    }
    default:
      return;
  }
}

function* documentSagas() {
  yield all([
    takeLatest(SET_AUTHENTICATED_APP_READY, subscribeBulkDocumentsSaga),
    takeLatest(PROCESS_DOCUMENT_FILE, processDocumentFileSaga),
    takeLatest(`${FETCH_DOCUMENTS}_FAIL`, onFetchDocumentsFailSaga),
    takeLatest(`${FETCH_DOCUMENT}_FAIL`, onFetchDocumentFailSaga),
    takeLatest(`${ARCHIVE_DOCUMENTS}_SUCCESS`, arhiveDocumentsSuccessSaga),
    takeLatest(`${ARCHIVE_DOCUMENT}_SUCCESS`, archiveDocumentSuccessSaga),
    takeLatest(`${REVIEW_DOCUMENTS}_SUCCESS`, reviewDocumentsSuccessSaga),
    takeLatest(
      [
        `${ADD_BULK_DOCUMENT_TYPES}_SUCCESS`,
        `${REMOVE_BULK_DOCUMENT_TYPES}_SUCCESS`,
      ],
      addOrRemoveBulkDocumentTypesSuccessSaga,
    ),
    takeLatest(RUN_DELETE_DOCUMENT, deleteDocumentSaga),
    takeLatest(UPDATE_DOCUMENT_STATE, processDocumentNotificationSaga),
    takeLatest(`${DELETE_DOCUMENT}_SUCCESS`, deleteNotificationSaga),
    takeLatest(PROCESS_DOCUMENTS_FILES, startUploadDocumentsSaga),
    takeLatest(
      SUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED,
      updateSinglePartySubscription,
    ),
  ]);
}

export default documentSagas;
