import {
  DOCUMENT_ACTION_TYPES,
  DocumentsWrapper,
  useConnectionBetweenTableAndURL as useDocumentsTableStateAndURL,
} from '@modules/document';
import { COLUMN_FIELDS } from '@modules/document/containers/DocumentsList/constants';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import type { ContextRecordRoutesParams } from '@modules/router/types';
import { useParams } from 'react-router-dom';

const EXCLUDED_ACTIONS = [
  DOCUMENT_ACTION_TYPES.assignToParty,
  DOCUMENT_ACTION_TYPES.detachFromParty,
  DOCUMENT_ACTION_TYPES.associateToProject,
];

const EXCLUDED_FILTERS = [COLUMN_FIELDS.projects];

const VIEWER_EXCLUDED_ACTIONS = Object.values(DOCUMENT_ACTION_TYPES);

export function ContextRecordDocuments() {
  const {
    updateURLFromTableState: handleTableStateChanged,
    getTableStateFromURL: getDocumentsTableState,
  } = useDocumentsTableStateAndURL();
  const { recordId, organizationSlugifyName } =
    useParams<ContextRecordRoutesParams>();

  const memberRole = useMemberRole();

  const excludedActions =
    memberRole === ROLES.VIEWER ? VIEWER_EXCLUDED_ACTIONS : EXCLUDED_ACTIONS;

  return (
    <DocumentsWrapper
      showViews={false}
      excludedFilters={EXCLUDED_FILTERS}
      initialState={getDocumentsTableState()}
      projectId={recordId}
      organizationSlugifyName={organizationSlugifyName!}
      excludedActions={excludedActions}
      onStateChanged={handleTableStateChanged}
    />
  );
}
