import type { ValueSetterParams } from '@common/components/Table';
import type { RecordAttribute } from '@graphql/types/graphql';

import {
  ATTRIBUTE_TYPES,
  type Address,
  type AttributeType,
} from '@modules/attributes/constants';

export const isValidValue = ({
  newValue,
  type,
  name,
  nonEmptyField,
}: {
  newValue: string | Address;
  type: AttributeType;
  name: string;
  nonEmptyField: boolean;
}) => {
  if (type === ATTRIBUTE_TYPES.Address) {
    return typeof newValue === 'object' && 'latitude' in newValue;
  }

  if (type === ATTRIBUTE_TYPES.Text && name === 'Name') {
    /**
     * @note we cannot unset the PrimaryRecord name
     */
    return Boolean((newValue as string).trim());
  }

  if (type === ATTRIBUTE_TYPES.Dropdown && nonEmptyField) {
    return !!newValue;
  }

  return true;
};

export const onAttributeValueChange = (
  params: ValueSetterParams<RecordAttribute>,
) => {
  const shouldUpdate = isValidValue({
    newValue: params.newValue,
    type: params.data.type,
    name: params.data.name,
    // @ts-expect-error - is a custom value only for the dropdown
    nonEmptyField: params.data?.nonEmptyDropdownOption || false,
  });

  if (shouldUpdate) {
    if (params.data.type === ATTRIBUTE_TYPES.Dropdown) {
      params.data = {
        ...params.data,
        selectedOptions: [params.newValue],
      };
    }
    if (params.data.type === ATTRIBUTE_TYPES.MultiSelect) {
      params.data = {
        ...params.data,
        selectedOptions: params.newValue,
      };
    } else {
      params.data = {
        ...params.data,
        value: params.newValue,
      };
    }

    // optimistic udpate of the row
    params.node!.updateData(params.data);
  }

  // set "valueChanged"
  return shouldUpdate;
};
