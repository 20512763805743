import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import type { TableControl } from '../Table';

type SelectionDropdownInnerProps = {
  selectedEntitiesCount: number;
  onSelectAll?: () => void;
  onSelectAllVisible?: () => void;
  onSelectNone?: () => void;
  totalCount: number;
  totalVisible: number;
  areAllSelected: boolean;
};

const SelectionDropdownInner = ({
  selectedEntitiesCount,
  onSelectAll = () => {},
  onSelectAllVisible = () => {},
  onSelectNone = () => {},
  totalCount,
  totalVisible,
  areAllSelected,
}: SelectionDropdownInnerProps) => {
  const areAllItemsVisible = totalVisible >= totalCount;

  return (
    <StyledWrapper
      data-cy="selectionDropdown"
      $areAllItemsVisible={areAllItemsVisible}
    >
      <Dropdown
        trigger={!areAllItemsVisible ? ['click'] : []}
        dropdownRender={() => (
          <Menu>
            <Menu.Item
              key="selection-dropdown-select-all"
              data-cy="selection-dropdown-select-all"
              onClick={() => onSelectAll()}
            >
              Select all ({totalCount})
            </Menu.Item>
            <Menu.Item
              key="selection-dropdown-select-visible"
              data-cy="selection-dropdown-select-visible"
              onClick={() => onSelectAllVisible()}
            >
              Select visible ({totalVisible})
            </Menu.Item>
            <Menu.Item
              key="selection-dropdown-select-none"
              data-cy="selection-dropdown-select-none"
              onClick={() => onSelectNone()}
            >
              Select none
            </Menu.Item>
          </Menu>
        )}
      >
        <div>
          {areAllSelected
            ? `All selected (${totalCount})`
            : `${selectedEntitiesCount} selected`}
          {!areAllItemsVisible && <CaretDownOutlined />}
        </div>
      </Dropdown>
    </StyledWrapper>
  );
};

type SelectionDropdownProps =
  | {
      control: TableControl;
    }
  | SelectionDropdownInnerProps;

const SelectionDropdown = (props: SelectionDropdownProps) => {
  if ('control' in props) {
    return (
      <SelectionDropdownInner
        selectedEntitiesCount={props.control.selection.selectedIds.length}
        onSelectAll={props.control.handleSelectAll}
        onSelectAllVisible={props.control.handleSelectVisible}
        onSelectNone={props.control.handleSelectNone}
        totalCount={props.control.counters.totalRows}
        totalVisible={props.control.counters.visibleRows}
        areAllSelected={props.control.selection.isSelectAllChecked}
      />
    );
  }

  return <SelectionDropdownInner {...props} />;
};

const StyledWrapper = styled.span<{
  $areAllItemsVisible: boolean;
}>`
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  cursor: ${(props) => (!props.$areAllItemsVisible ? 'pointer' : 'default')};
`;

export default SelectionDropdown;
