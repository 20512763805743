import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const UPDATE_DOCUMENT = `${namespace}/UPDATE_DOCUMENT`;
export const ACTION_KEY = 'updateDocument';

const UPDATE_DOCUMENT_MUTATION = gql`
  mutation updateDocument($payload: UpdateDocumentInput!) {
    updateDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const updateDocument = (payload, notUpdateStore) => ({
  type: UPDATE_DOCUMENT,
  payload: {
    key: ACTION_KEY,
    notUpdateStore,
    graphql: {
      mutation: UPDATE_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
