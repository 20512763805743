import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';
import {
  ADD_NEW_CARRIER_TO_DOCUMENT,
  ARCHIVE_DOCUMENT,
  ARCHIVE_DOCUMENTS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENTS,
  DETACH_DOCUMENTS_FROM_PARTY,
  REMOVE_CARRIER_FROM_DOCUMENT,
  UPDATE_DOCUMENT,
} from '@modules/document/actions';
import {
  setBulkOperationCompleted,
  setIsReloadPage,
} from '@modules/organization/actions';
import {
  getActiveOrganizationData,
  getActiveOrganizationId,
} from '@modules/organization/selectors';
import {
  ADD_PROJECT_UPDATES_SUBCRIPTION,
  BULK_DELETE_PROJECTS,
  CREATE_PROJECT,
  DELETE_PROJECTS,
  DETACH_DOCUMENTS_FROM_PROJECT,
  FETCH_CURRENT_PROJECT_BY_ID,
  REMOVE_PARTY_FROM_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_PROJECTS_ACTIVE_STATUS,
  fetchCurrentProjectById,
  markProjectAsViewed,
  updateSubscribedProjectsMap,
} from '@modules/project/actions';
import { push } from '@modules/router/actions';
import {
  SET_CURRENT_PROJECT_ID,
  setProjectAsCurrent,
} from '@modules/system-settings/actions';
import { getCurrentProjectId } from '@modules/system-settings/selectors';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import { getCurrentOrganizationMember } from '@modules/organization-member/selectors';
import {
  EventType,
  bulkEventAnalyticsMessage,
  bulkEventDefaultMessage,
} from '../constants';
import { getCurrentProject, getSubscribedProjectsMap } from '../selectors';

const BULK_PROJECTS_ACTIONS_PROCESSED_SUBSCRIPTION = gql`
  subscription onBulkProjectsProcessed($organizationId: String!) {
    bulkProjectsProcessed(organizationId: $organizationId)
  }
`;

const PROJECT_UPDATED_SUBSCRIPTION = gql`
  subscription onProjectUpdated($projectId: ObjectId, $memberId: ObjectId!) {
    projectUpdated(projectId: $projectId, memberId: $memberId) {
      project {
        _id
        name
      }
    }
  }
`;

function* fetchCurrentProjectSaga() {
  const currentProjectId = yield select(getCurrentProjectId);
  const organization = yield select(getActiveOrganizationId);

  if (organization) {
    yield fork(subscribeToProjectSaga, organization);
  }

  if (currentProjectId) {
    yield putResolve(fetchCurrentProjectById(currentProjectId));
  } else {
    yield put({
      type: `${FETCH_CURRENT_PROJECT_BY_ID}_SUCCESS`,
      payload: R.assocPath(['data', 'getProjectById'], {}, {}),
    });
  }
}

function* handleProjectUpdates({ data }) {
  const { project } = data?.projectUpdated || {};

  yield notify.success(
    `Compliance synchronization for ${project.name} complete`,
  );
}

export function* subscribeToProjectUpdated(projectId) {
  const currentOrganizationMember = yield select(getCurrentOrganizationMember);

  const projectUpdatesChannel = yield call(() =>
    eventChannel((emit) => {
      const subscription = graphqlClient
        .subscribe({
          query: PROJECT_UPDATED_SUBSCRIPTION,
          variables: { projectId, memberId: currentOrganizationMember._id },
          fetchPolicy: 'no-cache',
        })
        .subscribe({
          next(data) {
            emit(data);
          },
        });

      const unsubscribe = () => {
        subscription.unsubscribe();
      };

      return unsubscribe;
    }),
  );

  // eslint-disable-next-line fp/no-loops
  while (true) {
    try {
      const payload = yield take(projectUpdatesChannel);
      yield fork(handleProjectUpdates, payload);
    } catch (err) {
      console.error('Socket error:', err);
    }
  }
}

function* addProjectUpdatesSubscription({ payload }) {
  const subscribedProjectsMap = yield select(getSubscribedProjectsMap);

  if (!subscribedProjectsMap[payload]) {
    yield fork(subscribeToProjectUpdated, payload);
    yield put(updateSubscribedProjectsMap(payload));
  }
}

export function* subscribeToProjectSaga(organizationId) {
  const bulkProjectsChannel = yield call(() =>
    eventChannel((emit) => {
      const subscription = graphqlClient
        .subscribe({
          query: BULK_PROJECTS_ACTIONS_PROCESSED_SUBSCRIPTION,
          variables: {
            organizationId,
          },
        })
        .subscribe({
          next(data) {
            emit(data);
          },
        });

      const unsubscribe = () => {
        subscription.unsubscribe();
      };

      return unsubscribe;
    }),
  );
  // eslint-disable-next-line fp/no-loops
  while (true) {
    try {
      const payload = yield take(bulkProjectsChannel);
      yield fork(updateStateBulkProjectsSubscription, payload);
    } catch (err) {
      console.error('Socket error:', err);
    }
  }
}

function* updateStateBulkProjectsSubscription(payload) {
  const type = R.pathOr('', ['data', 'bulkProjectsProcessed', 'type'], payload);
  const result = R.pathOr(
    '',
    ['data', 'bulkProjectsProcessed', 'data'],
    payload,
  );

  switch (type) {
    case EventType.bulkUpdateProjectsActiveStatusSuccess:
    case EventType.bulkDeleteProjectsSuccess: {
      yield put(
        setBulkOperationCompleted({ status: true, entity: 'projects' }),
      );
      yield notify.success(result.message || bulkEventDefaultMessage[type]);
      yield call(trackEvent, bulkEventAnalyticsMessage[type]);
      break;
    }
    case EventType.bulkUpdateProjectsActiveStatusFail:
    case EventType.bulkDeleteProjectsFail: {
      yield notify.error(result.message);
      break;
    }
    default:
      return;
  }
}

function* fetchProjectByIdSaga({ payload, redirectTo }) {
  const previous = yield select(getCurrentProjectId);

  if (payload) {
    yield all([
      putResolve(fetchCurrentProjectById(payload)),
      putResolve(markProjectAsViewed(payload)),
    ]);
  } else {
    yield put({
      type: `${FETCH_CURRENT_PROJECT_BY_ID}_SUCCESS`,
      payload: R.assocPath(['data', 'getProjectById'], {}, {}),
    });
  }

  const currentProject = yield select(getCurrentProject);
  const organization = yield select(getActiveOrganizationData);

  if (!(previous === R.prop('_id', currentProject))) {
    yield put(setIsReloadPage(true));

    notify.success(
      `Switched to ${R.prop(
        'name',
        isEmpty(currentProject) ? organization : currentProject,
      )}`,
    );
  }

  if (redirectTo) {
    yield put(push(redirectTo));
  }
}

function* createProjectSuccessSaga(payload) {
  yield call(trackEvent, 'User created a project');

  yield put(setProjectAsCurrent(R.prop('_id', getGraphqlPayload(payload))));
}

function* updateProjectSuccessSaga(payload) {
  const currentProjectId = yield select(getCurrentProjectId);
  const updatedProjectId = R.prop('_id', getGraphqlPayload(payload));

  yield call(trackEvent, 'User edited a project');

  if (currentProjectId === updatedProjectId) {
    yield put(setIsReloadPage(true));
  }
}

function* deleteProjectsSaga(payload) {
  const projectIds = R.compose(
    R.map((x) => x._id),
    getGraphqlPayload,
  )(payload);

  yield call(trackEvent, 'User deleted projects');

  const currentProjectId = yield select(getCurrentProjectId);

  if (projectIds.includes(currentProjectId)) {
    yield put(setProjectAsCurrent(null));
    yield put({
      type: `${FETCH_CURRENT_PROJECT_BY_ID}_SUCCESS`,
      payload: R.assocPath(['data', 'getProjectById'], {}, {}),
    });
  }
}

function* bulkDeleteProjectsSaga(payload) {
  const selectedIds = R.path(
    [
      'meta',
      'previousAction',
      'payload',
      'graphql',
      'variables',
      'payload',
      'filterQuery',
      '_id',
    ],
    payload,
  );

  if (!selectedIds) {
    // if there are no selectedIds it means the user selected ALL the documents (not just the visibles in the table) on top of the selected filters
    // in this case we don't know if the current project has been selected or not, thus we just fallback to the org for safety reason
    yield put(setProjectAsCurrent(null));
  } else {
    const currentProjectId = yield select(getCurrentProjectId);

    if (selectedIds.includes(currentProjectId)) {
      yield put(setProjectAsCurrent(null));
    }
  }

  yield call(trackEvent, 'User deleted projects');
}

function* updateProjectActiveStatusSuccessSaga(payload) {
  const isActive = R.prop('isActive', R.head(getGraphqlPayload(payload)));

  yield call(
    trackEvent,
    isActive
      ? 'User marked a project as active'
      : 'User marked a project as inactive',
  );
}

function* onSetProjectFailSaga() {
  yield notify.error('Selected project is not available.');
  yield put(setProjectAsCurrent(null));
}

function* projectSagas() {
  yield all([
    takeLatest(
      [
        SET_AUTHENTICATED_APP_READY,
        `${UPDATE_DOCUMENT}_SUCCESS`,
        `${DELETE_DOCUMENT}_SUCCESS`,
        `${DELETE_DOCUMENTS}_SUCCESS`,
        `${ARCHIVE_DOCUMENT}_SUCCESS`,
        `${ARCHIVE_DOCUMENTS}_SUCCESS`,
        `${DETACH_DOCUMENTS_FROM_PARTY}_SUCCESS`,
        `${REMOVE_PARTY_FROM_PROJECTS}_SUCCESS`,
        `${DETACH_DOCUMENTS_FROM_PROJECT}_SUCCESS`,
        `${ADD_NEW_CARRIER_TO_DOCUMENT}_SUCCESS`,
        `${REMOVE_CARRIER_FROM_DOCUMENT}_SUCCESS`,
      ],
      fetchCurrentProjectSaga,
    ),
    takeLatest(SET_CURRENT_PROJECT_ID, fetchProjectByIdSaga),
    takeLatest(`${FETCH_CURRENT_PROJECT_BY_ID}_FAIL`, onSetProjectFailSaga),
    takeLatest(`${CREATE_PROJECT}_SUCCESS`, createProjectSuccessSaga),
    takeLatest(`${UPDATE_PROJECT}_SUCCESS`, updateProjectSuccessSaga),
    takeLatest(`${BULK_DELETE_PROJECTS}_SUCCESS`, bulkDeleteProjectsSaga),
    takeLatest(`${DELETE_PROJECTS}_SUCCESS`, deleteProjectsSaga),
    takeLatest(
      `${UPDATE_PROJECTS_ACTIVE_STATUS}_SUCCESS`,
      updateProjectActiveStatusSuccessSaga,
    ),
    takeEvery(ADD_PROJECT_UPDATES_SUBCRIPTION, addProjectUpdatesSubscription),
  ]);
}

export default projectSagas;
