import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  ContextRecordsQuery,
  PrimaryRecordsQuery,
} from '@graphql/types/graphql';
import {
  ContextRecordStatus,
  RECORD_TYPES,
  type RecordTypes,
} from '@trustlayer/common';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_CONTEXT } from '../constants';

type RecordResult = {
  _id: string;
  name: string;
};

type PivotalRecordTypes = Exclude<RecordTypes, typeof RECORD_TYPES.Request>;

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DEFAULT_PAGE_SIZE,
};

const ACTIVE_RECORDS_FILTER = {
  name: 'status',
  operator: FILTER_OPERATORS.in,
  value: [ContextRecordStatus.Active],
};

const CONTEXT_RECORDS_QUERY = graphql(`
  query ContextRecords($input: ContextRecordsInput) {
    contextRecords(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

const PRIMARY_RECORDS_QUERY = graphql(`
  query PrimaryRecords($input: PrimaryRecordsInput) {
    primaryRecords(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

export type UseRecordsProps = {
  skip?: boolean;
  source: PivotalRecordTypes;
};

const RECORD_TYPE_CONFIG: Record<
  PivotalRecordTypes,
  {
    query: typeof PRIMARY_RECORDS_QUERY | typeof CONTEXT_RECORDS_QUERY;
    defaultRecord?: RecordResult;
  }
> = {
  [RECORD_TYPES.Primary]: {
    query: CONTEXT_RECORDS_QUERY,
    defaultRecord: DEFAULT_CONTEXT,
  },
  [RECORD_TYPES.Context]: {
    query: PRIMARY_RECORDS_QUERY,
  },
};

export const useRecords = ({ skip, source }: UseRecordsProps) => {
  const [records, setRecords] = useState<RecordResult[]>([]);
  const [recordsTotalCount, setRecordsTotalCount] = useState(0);
  const recordTypeConfig = RECORD_TYPE_CONFIG[source];

  const [fetchRecords, { loading, fetchMore }] = useLazyQuery(
    recordTypeConfig.query,
    {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
          filter: { and: [ACTIVE_RECORDS_FILTER] },
        },
      },
    },
  );

  const isPrimaryRecordQuery = (
    result: ContextRecordsQuery | PrimaryRecordsQuery,
    source: RecordTypes,
  ): result is PrimaryRecordsQuery => {
    return source === RECORD_TYPES.Context;
  };

  const onCompleted = (result: ContextRecordsQuery | PrimaryRecordsQuery) => {
    const records = isPrimaryRecordQuery(result, source)
      ? result.primaryRecords
      : result.contextRecords;
    setRecords(records.nodes);
    setRecordsTotalCount(records.totalCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const filterRecordsByName = useMemo(
    () =>
      debounce((name: string) => {
        fetchRecords({
          variables: {
            input: {
              ...DEFAULT_INPUT,
              offset: 0,
              filter: {
                and: [
                  ACTIVE_RECORDS_FILTER,
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: name,
                  },
                ],
              },
            },
          },
          onCompleted,
        });
      }, 500),
    [fetchRecords],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (skip) {
      return;
    }

    fetchRecords({
      onCompleted,
    });
  }, [fetchRecords, skip]);

  return {
    records: recordTypeConfig.defaultRecord
      ? [recordTypeConfig.defaultRecord, ...records]
      : records,
    recordsTotalCount,
    loading,
    filterRecordsByName,
    fetchMoreRecords: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: records.length,
            filter: { and: [ACTIVE_RECORDS_FILTER] },
          },
        },
      });
    },
  };
};
