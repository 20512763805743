import { Col, Row, Typography } from 'antd';
import type { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import Box from '@common/components/Box';

const { Paragraph } = Typography;

interface RequirementCardProps {
  icon?: ComponentType<{ className?: string }>;
  title: string;
  description?: ReactNode;
  action?: ReactNode;
  className?: string;
}

const RequirementCard = ({
  icon: Icon,
  title,
  description,
  action,
  className,
  ...rest
}: RequirementCardProps) => (
  <StyledCard className={className} {...rest}>
    <Row wrap={false}>
      {Icon && (
        <Col flex="28px">
          <Icon className="cardIcon" />
        </Col>
      )}
      <Col flex="auto">
        <div className="title">{title}</div>
        {typeof description === 'string' ? (
          <Paragraph ellipsis={{ rows: 1, expandable: true }}>
            {description}
          </Paragraph>
        ) : (
          description
        )}
      </Col>
      <Col>{action}</Col>
    </Row>
  </StyledCard>
);

const StyledCard = styled(Box)`
  margin-bottom: 20px;
  padding: 20px;

  .cardIcon {
    font-size: 18px;
    margin-top: 6px;
    color: ${({ theme }) => theme.colors.blue};
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 2em;
  }

  .ant-typography {
    font-size: 13px;
    white-space: normal;
  }

  button {
    margin-left: 15px;
  }
`;

export default RequirementCard;
