import { Typography } from 'antd';
import type React from 'react';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';
import styled from 'styled-components';

interface LinkProps extends RouterLinkProps {
  // Add any additional props you need here
}

export const LinkCell: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  return (
    <RouterLink to={to} {...rest}>
      <StyledLink>{children}</StyledLink>
    </RouterLink>
  );
};

const StyledLink = styled(Typography.Text)`
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.2s ease-in-out;
  }
`;

export default LinkCell;
